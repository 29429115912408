.tabs-container {
    display       : flex;
    flex-direction: column;
    width         : 100%;
}

.tabs-header {
    display   : flex;
    flex-wrap : wrap;
    list-style: none;
    padding   : 0;
    margin    : 0;

    li {
        .sans-medium;
        margin          : 3px;
        border-radius   : 58px;
        border: solid 1px @light-second;
        background-color: #fff;
        color: black;
        cursor          : pointer;
        transition      : background .2s ease-in;
        padding: 17px 20px;
        font-size: 11px;
        height: 32px;
        line-height: 1.45;
        display: flex;
        flex-direction: row;
        align-items: center;
        
        @media (min-width:@screen-width-tablet) {
            padding         : 12px 20px;
            font-size       : 16px;
            height          : 44px;
            line-height: 1.5;
        }

        &:hover,
        &.default-tab,
        &.current-tab {
            border          : solid 1px @accent;
            background-color: @accent;
            color           : #fff;
        }
    }
}

.searchresultheader {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
    align-items: center;

    p {
        margin: 0;

        @media (max-width:@screen-width-desktop) {
            text-align: center;
        }

        @media (max-width:@screen-width-desktop) {
            order: 1;
        }
    }

    .product-count {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        top: 10px;
        font-family: @font-family-serif;
        font-size: 20px;
    }


    &__button_left {
        justify-content: left;
        display: flex;

        @media (max-width:@screen-width-tablet) {
            margin: 50px 0 0 0;
        }
    }

    &__button_right {
        justify-content: right;
        display: flex;

        @media (max-width:@screen-width-tablet) {
            margin: 50px 0 0 0;
        }

        .total-active-filters {
            background-color: @green;
            border-radius: 50%;
            width: 27px;
            height: 27px;
            font-size: 12px;
            margin-left: 8px;
            display: inline-flex;
            justify-content: center;
            align-items: center;
        }
    }
}

.orderby {
    display    : flex;
    flex-wrap  : wrap;
    align-items: center;

    @media (min-width:@screen-width-desktop) {
        margin-right: 20px;
    }

    @media (max-width:@screen-width-tablet) {
        padding  : 20px 0 0 0;
        width    : 75%;
        font-size: 14px;
    }

    span {
        margin: 0 10px 5px 0;

        @media (max-width:@screen-width-desktop) {
            width: 100%;
        }
    }

    a {
        text-decoration: underline;
        margin         : 0 20px 5px 0;

        @media (min-width:@screen-width-desktop) {
            margin: 0 20px 0 0;
        }

        &.current {
            font-weight: 600;
        }

        &:last-child {
            margin: 0 0 5px 0;

            @media (min-width:@screen-width-desktop) {
                margin: 0;
            }
        }
    }
}