.productuspblock {
    ul {
        max-width: 350px;
        list-style-type: none;
        display: flex;
        flex-wrap: wrap;
        padding-left: 0px;
        font-size: 12px;

        li {
            margin-right: 20px;
            margin-left: 3px;

            &:before {
                content: "";
                background-image: url(/Resources/Graphics/check.svg);
                background-size: 16px;
                background-repeat: no-repeat;
                width: 16px;
                height: 16px;
                position: relative;
                display: inline-block;
                top: 3px;
                right: 3px;
            }
        }
    }
}