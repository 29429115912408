.confirm-store-popup {
    display: none;
    width: 355px;
    height: 225px;
    border-radius: 4px;
    border-color: #ffffff;
    border-width: 6px;
    border-style: solid;
    box-shadow: 0 0 10px 0 rgba(87, 86, 86, 0.2);
    background-color: @light;
    padding: 45px;
    position: fixed;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2000;
    justify-content: space-between;
    flex-direction: column;

    @media (min-width: @screen-width-desktop) {
        top: 170px;
        left: 210px;
    }

    @media (min-width: @screen-width-xl-desktop) {
        left: 255px;
    }

    @media (min-width: @screen-width-xxl-desktop) {
        left: 250px;
    }

    @media (min-width: @screen-width-xxxl-desktop) {
        left: 365px;
    }

    &__enabled {
        display: flex;
    }

    span {
        display: flex;
        justify-content: space-between;
        margin-top: 35px;
    }

    a {
        text-decoration: underline;
        color: black;
    }

    animation: fadeOut ease 1s;
    -webkit-animation: fadeOut ease 1s;
    -moz-animation: fadeOut ease 1s;
    -o-animation: fadeOut ease 1s;
    -ms-animation: fadeOut ease 1s;

    animation-delay:6s;
    animation-fill-mode: forwards;
    -webkit-animation-delay:6s;
    /* Safari and Chrome */
    -webkit-animation-fill-mode: forwards;

    @keyframes fadeOut {
        0% {
            opacity: 1;
        }

        100% {
            opacity: 0;
        }
    }

    @-moz-keyframes fadeOut {
        0% {
            opacity: 1;
        }

        100% {
            opacity: 0;
        }
    }

    @-webkit-keyframes fadeOut {
        0% {
            opacity: 1;
        }

        100% {
            opacity: 0;
        }
    }

    @-o-keyframes fadeOut {
        0% {
            opacity: 1;
        }

        100% {
            opacity: 0;
        }
    }

    @-ms-keyframes fadeOut {
        0% {
            opacity: 1;
        }

        100% {
            opacity: 0;
        }
    }
}