.uspblock {
    padding: 80px 0;
    background: @lightgreen;
    text-align: center;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    @media (min-width: @screen-width-lg-desktop) {
        padding: 100px 0;
    }

    &__heading {
        margin: 0 auto 30px auto!important;
        position: relative;

        @media (min-width: @screen-width-lg-desktop) {
            max-width: 40%;
        }
    }

    &__columns {
        max-width: 1024px;
        margin: 0 auto;
        position: relative;
    }

    &__columnheading {
        font-size: 20px;
        text-align: center;

        @media (min-width: @screen-width-lg-desktop) {
            text-align: left;
        }

        svg {
            position: relative;
            margin-right: 5px;
            top: 5px;
        }
    }

    &__columntext {
        text-align: center;
        font-size: 14px;
        line-height: 1.75;

        @media (min-width: @screen-width-lg-desktop) {
            text-align: left;
            padding-left: 28px;
        }

        p {
            font-size: 14px;
            line-height: 1.75;
        }
    }

    .btn {
        margin-top: 30px;
        display: inline-block;
        position: relative;
    }

    &.has-image {
        position: relative;
        overflow: hidden;

        .uspblock__heading {
            color: #fff;
        }

        .uspblock__columnheading, .uspblock__columntext {
            color: #fff;

            svg {
                path {
                    fill: #fff;
                }
            }

            p {
                color: #fff;
            }
        }
    }
}

.uspblocklist{
    ul{
        list-style: none;
        padding: 0;

        @media (min-width: @screen-width-desktop) {
           display: flex;
           justify-content: center;
        }
    }
    li{
        .sans-medium;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 10px;
        font-size: 14px;
    }
    svg{
        width: 22px;
        height: 22px;
        margin-right: 5px;
    }
}
