header {
    z-index: 100;
    background-color: #fff;
    width: 100%;

    &.sticky {

        @media (max-width: @screen-width-desktop) {
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;

            .breadcrumbs-container {
                display: none;
            }
        }
    }

    .broadcast-message {
        position: relative;
        background: @pale;
        text-align: center;
        padding: 15px 0;
        color: @darkgrey;

        &-closebtn {
            background: none;
            padding: 0;
            border: none;
            position: absolute;
            right: 20px;
            top: 15px;

            @media (min-width: @screen-width-desktop) {
                top: 50%;
                transform: translateY(-50%);
            }
        }

        p {
            margin: 0;
            font-size: 14px;
            line-height: 1.57;
            padding: 0 40px;

            @media (min-width: @screen-width-xl-desktop) {
                font-size: 16px;
                line-height: 1.75;
            }
        }

        a {
            text-decoration: underline;
        }
    }

    .store-choice {
        background: @light;
        text-align: center;
        padding: 15px 0;
        display: block;

        &.storechoice-hidden {
            display: none;
        }

        &-heading {
            .sans-medium;
            font-size: 14px;
            line-height: 1.57;
            text-align: center;
            display: block;
            padding-bottom: 10px;
            min-height: 55px;

            @media (min-width: @screen-width-tablet) {
                font-size: 16px;
                line-height: 1.5;
                min-height: auto;
            }

            @media (min-width: @screen-width-lg-desktop) {
                width: 60%;
                margin: 0 auto;
            }
        }

        .btn {
            display: inline-block;
        }

        &-content {
            display: flex;
            overflow-x: auto;
            font-size: 12px;
            text-align: left;
            white-space: nowrap;
            justify-content: space-between;

            .storename,
            .openhours,
            .address {
                .sans-medium;
                font-size: 11px;
                line-height: 1.45;
                color: @accent;
                display: inline-block;
                margin-right: 15px;

                @media (min-width: @screen-width-tablet) {
                    font-size: 12px;
                    line-height: 1.5;
                }
            }
        }

        .right-side {
            display: none;

            @media (min-width: @screen-width-tablet) {
                display: flex;
            }

            div {
                margin-right: 15px;
            }
        }
    }
}

.search-input-wrapper {
    position: relative;
    width: 100%;
    height: 40px;


    @media (min-width: @screen-width-desktop) {
        width: 300px;
        height: 50px;
        position: absolute;
        // left: 65px;
        left: 10px;
        right: 0;
        margin: auto;
    }

    @media (min-width: @screen-width-lg-desktop) {
        left: 0;
        width: 500px;
    }

    @media (min-width: @screen-width-xl-desktop) {
        width: 605px;
    }

    .search {
        &-input {
            width: 100%;
            border: 1px solid @light-third;
            border-radius: 4px;
            background-color: fade(@light, 30%);
            height: 40px;
            padding: 0 15px;
            font-size: 16px;
            padding-left: 40px;

            @media (min-width: @screen-width-tablet) {
                font-size: 16px;
            }

            @media (min-width: @screen-width-desktop) {
                width: 330px;
                height: 50px;
            }

            @media (min-width: @screen-width-lg-desktop) {
                width: 490px;
            }

            @media (min-width: @screen-width-xl-desktop) {
                width: 605px;
            }
        }

        &-button {
            position: absolute;
            left: 5px;
            top: 4px;
            padding: 0;
            border-radius: 50%;
            width: 32px;
            height: 32px;
            display: block;
            background-color: transparent;
            background-image: url("/Resources/Graphics/search-icon.svg");
            background-size: 16px;
            background-repeat: no-repeat;
            background-position: center;

            @media (min-width: @screen-width-desktop) {
                width: 38px;
                height: 38px;
                background-size: 19px;
                top: 6px;
            }

            &:hover,
            &:active,
            &:focus {
                outline: none;
            }
        }
    }
}