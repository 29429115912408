@off-white: #fcfcfc;
@off-black: #19191e;

@primary--default: #6600ff;
@primary--hover: #8766ff;
@primary--active: #af99ff;
@primary--disabled: #d9cfff;

@secondary--default: #ffffff;
@secondary--hover: #e6e6e6;
@secondary--active: #aaaaaa;
@secondary--disabled: #B2B2B2;

.color--grayscale {
  filter: grayscale(100%);
}

.color--black-to-white {
  filter: invert(100%);
}

.color--black-to-green {
  filter: invert(1) brightness(0.5) sepia(1) saturate(10000%) hue-rotate(90deg);
}
