.videoblock {
    padding: 40px 0;
    margin: 0 auto;

    &__heading {
        text-align: center;
    }

    &__list {
        display: flex;
        justify-content: center;

        button {
            position: relative;
            background: none;
            border: none;
            padding: 0;
            width: calc(100% - 30px);
            margin: 5px 15px;
            height:auto;

            &:hover {
                svg circle {
                    opacity: 1;
                }
            }

            img {
                width: 100%;
            }

            svg {
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
            }
        }
    }

    .popup-modal {
        max-width: 1200px;
        padding: 60px 20px;

        @media (min-width: @screen-width-tablet) {
            padding: 45px;
        }

        @media (min-width: @screen-width-desktop) {
            width: 620px;
        }

        @media (min-width: @screen-width-lg-desktop) {
            width: 920px;
        }

        @media (min-width: @screen-width-xl-desktop) {
            width: 1200px;
        }


        iframe {
            width: 100%;
            height: 180px;

            @media (min-width: @screen-width-desktop) {
                height: 320px;
            }

            @media (min-width: @screen-width-lg-desktop) {
                height: 520px;
            }

            @media (min-width: @screen-width-xl-desktop) {
                height: 620px;
            }
        }
    }

    .video-1 {
        display: flex;
        justify-content: center;
        margin-top: 20px;

        iframe {
            width: 100%;
            height: 180px;

            @media (min-width: @screen-width-desktop) {
                width: 620px;
                height: 320px;
            }

            @media (min-width: @screen-width-lg-desktop) {
                width: 920px;
                height: 520px;
            }

            @media (min-width: @screen-width-xl-desktop) {
                width: 1200px;
                height: 620px;
            }
        }
    }

    .video-2 {
        button {
            @media (min-width: @screen-width-tablet) {
                width: calc(50% - 30px);
                margin: 15px;
                height:auto;
            }
        }
    }

    .video-3 {
        button {
            @media (min-width: @screen-width-tablet) {
                width: calc(50% - 30px);
                margin: 15px;
            }

            @media (min-width: @screen-width-lg-desktop) {
                width: calc(33.3% - 30px);
            }
        }
    }

    .video-4 {
        button {
            @media (min-width: @screen-width-tablet) {
                width: calc(50% - 30px);
                margin: 15px;
            }

            @media (min-width: @screen-width-lg-desktop) {
                width: calc(25% - 30px);
            }
        }
    }
}
