.orderconfirmationpage {

    .confirmation-info {
        padding: 40px 0 80px 0;
    }

    .cartpage__greenbox {
        justify-content: flex-end;
    }

    .big-heading {
        padding-top: 60px;
    }

    .big-heading, .medium-heading {
        text-align: center;
    }

    .confirmation-error {        
        text-align: center;
    }    
}
