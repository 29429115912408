.accordion-item {
    position     : relative;
    border-bottom: 1px solid #000;
    z-index      : 10;
    padding      : 0;

    .accordion-heading {
        margin  : 0;
        position: relative;

        &:after {
            content    : "";
            position   : absolute;
            background: url(/Resources/Graphics/plus.svg);
            background-size: 14px;
            right: 10px;
            top: 5px;
            width: 14px;
            height: 14px;
        }

        &.accordion-active {
            &:after {
                content    : "";
                background: url(/Resources/Graphics/minus.svg);
                top: 10px;
                top: 3px;
            }
        }
    }

    .accordion-content {
        height    : 0;
        transition: height 0.4s ease-in-out;
        overflow  : hidden;

        &:not(.accordion-visible) {
            display: none;
        }

        p,
        ul {
            padding-top: 15px;
        }

        @media (min-width: @screen-width-tablet) {
            display: block;
        }
    }
}