h1,
.h1 {
    font-size     : @font-size-h1;
    line-height   : 1.12;
    font-family   : @font-family-serif;
    margin        : 0 0 15px 0;

    @media (min-width: @screen-width-tablet) {
        font-size     : @font-size-h1-desktop;
    }
}

h2,
.h2 {
    font-size     : @font-size-h2;
    line-height   : 1.13;
    font-family   : @font-family-serif;
    margin        : 0 0 15px 0;

    @media (min-width: @screen-width-tablet) {
        font-size     : @font-size-h2-desktop;
        line-height   : 1.15;
    }
}

h3,
.h3 {
    font-size     : @font-size-h3;
    line-height   : 1.15;
    font-family   : @font-family-serif;
    margin        : 0 0 15px 0;

    @media (min-width: @screen-width-tablet) {
        font-size     : @font-size-h3-desktop;
        line-height   : 1.12;
    }
}

h4,
.h4 {
    font-size     : @font-size-h4;
    line-height   : 1.2;
    font-family   : @font-family-serif;
    margin        : 0 0 15px 0;
    
    @media (min-width: @screen-width-tablet) {
        font-size     : @font-size-h4-desktop;
        line-height   : 1.125;
    }
}

h5,
.h5 {
    .sans-medium;
    font-size     : @font-size-h5;
    line-height   : 1.22;
    margin        : 0 0 15px 0;

    @media (min-width: @screen-width-tablet) {
        font-size: @font-size-h5-desktop;
        line-height   : 1.4;
    }
}

.big-heading {
    line-height   : 1.18;
    font-size     : 34px;
    font-weight   : normal;
    font-family   : @font-family-serif;
    font-weight   : bold;

    @media (min-width: @screen-width-tablet) {
        font-size     : @font-size-xl;
        line-height   : 1.13;
    }
}

p {
    font-family: @font-family-sans-serif;
    // font-size     : @font-size-base;
    // font-weight   : normal;
    // font-style    : normal;
    // line-height   : 1.57;
    margin        : 0 0 15px 0;

    // @media (min-width: @screen-width-tablet) {
    //     font-size     : 16px;
    //     line-height   : 1.75;
    // }
}

.preamble {
    font-size     : @font-size-medium;
    line-height   : 1.44;
    text-align    : center;

    @media (min-width: @screen-width-tablet ) {
        font-size: 20px;
        line-height   : 1.5;
    }
}

strong,
b {
    .sans-bold;
}

blockquote {
    font-family   : @font-family-serif;
    font-size     : 20px;
    font-weight   : bold;
    line-height   : 1.5;
    margin        : 0;

    @media (min-width: @screen-width-tablet) {
        font-size     : 24px;
    }

    p {
        font-family   : @font-family-serif;
        font-size     : 20px;
        font-weight   : bold;
        line-height   : 1.5;
        margin        : 0;

        @media (min-width: @screen-width-tablet) {
            font-size     : 24px;
        }
    }
}

q{
    font-size: @font-size-h3;
    line-height: 1.31;
    color:@accent;
    font-family   : @font-family-serif;

    @media (min-width: @screen-width-tablet) {
        font-size: @font-size-h3-desktop;
        line-height: 1.25;
    }
}