.textblock {
    &-inner {
        padding: 15px 0;
    }

    .accordion-content{

        p{
            a{
                .sans-medium;
                font-size: 16px;
                color: @accent-second;
            }
        }
        h4{
            .sans-medium;
            font-size: 16px;
            color: @accent-second;
        }
    }
}