.startpage {
    .big-heading {
        text-align: center;
        padding-top: 40px;

        @media (min-width: @screen-width-desktop) {
            padding-top: 60px;
        }
    }
}
