html {
    /*overflow-x: hidden;*/
	margin: 0;
	padding: 0;

    body {
        .sans-regular;
        flex-direction  : column;
        background-color: @background;
        font-size: @font-size-base;
        line-height     : 1.57;
        margin          : 0;
		padding			: 0;

        @media (min-width: @screen-width-tablet ) {
            font-size: 16px;
            line-height: 1.75;
        }
    }
}

.main {
    color           : @black;
    background-color: @background;

    &--cancan {
        & h2:not(.footer h2),
        & h3:not(.footer h3),
        & p:not(.footer p),
        & a:not(.footer a),
        & span:not(.footer span),
        & button:not(.footer button) {
            font-family: "Neufile Grotesk Extended", sans-serif;
            font-weight: normal;
            font-style: normal;
        }
    }
}

a {
    .sans-medium;
    text-decoration: none;
    color          : @accent;
    line-height: 1.43;
    

    @media (min-width: @screen-width-tablet ) {
        line-height: 1.25;
    }

    &:hover {
        transition: color .5s;
        // text-decoration: underline;
    }
}

.arrow-link {
    color      : @black;

    &:after {
        content          : "";
        background       : url('/Resources/Graphics/right-arrow-icon.svg');
        background-size  : 12px;
        background-repeat: no-repeat;
        width            : 12px;
        height           : 10px;
        display          : inline-block;
        margin-left      : 5px;
    }
}

.container {
    @media (min-width: @screen-width-xxl-desktop) {
        max-width: 1440px;
        padding-left: 75px;
        padding-right: 75px;
    }
}

.container-sm {
    max-width: 700px;
}

.hide-tablet-up {
    @media (min-width: @screen-width-tablet ) {
        display: none;
    }
}

.hide-tablet-down {
    @media (max-width: @screen-width-tablet ) {
        display: none;
    }
}

img {
    max-width: 100%;
    height   : auto;
}

.visually-hidden {
    position: absolute;
    left    : -10000px;
    top     : auto;
    width   : 1px;
    height  : 1px;
    overflow: hidden;
}

.overflow-hidden{
    overflow: hidden;
}

.gutter {
    padding: 15px;
}

.language-selection-bar {
    display: none;
}

.black-scrollbar {
    overflow-y: auto;

    &::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        background-color  : #F5F5F5;
    }

    &::-webkit-scrollbar {
        width           : 4px;
        background-color: @lightgrey;
    }

    &::-webkit-scrollbar-thumb {
        background-color: #000000;
        border-radius   : 8px;
    }
}

hr {
    border: 1px solid #eee;
}

.information-message {
    margin-bottom   : 20px;
    padding         : 15px 10px;
    background-color: @green;
}

.sans-regular {
    font-family: @font-family-sans-serif;
    font-weight: 400;
}

.sans-medium {
    font-family: @font-family-sans-serif-medium;
    font-weight: 500;
}

.sans-bold {
    font-family: @font-family-sans-serif-bold;
    font-weight: 700;
}

// hide safaris extra icons
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
    -webkit-appearance:none;
}