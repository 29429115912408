.menublock {
    ul {
        list-style: none;
        padding   : 0 0 8px 0;
        margin    : 0;

        li {
            padding-bottom: 12px;

            a {
                color      : @accent-second;
            }
        }
    }
}