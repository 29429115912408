button,
.btn {
    .sans-medium;
    border-radius : 4px;
    font-size     : @font-size-base;
    border        : none;
    color         : #fff;
    padding       : 16px 50px;
    cursor        : pointer;
    transition    : background .2s ease-in;
    height: 46px;
    line-height: 1;
    
    @media (min-width: @screen-width-tablet) {
        height: 50px;
        font-size: 16px;
        line-height: 1.25;
    }

    &-green {
        background: @accent-second;

        &:hover,
        &:focus,
        &:active {
            background: #656F71;
        }
    }

    &-green-outline {
        border    : 1px solid @accent-second;
        background-color: initial;
        color: @accent-second;

        &:hover {
            background: @accent-second;
            color:#fff;

            &:disabled {
                background: @light-second;
                color: @dark-third;
            }
        }
    }

    &-grey {
        background: @lightgrey;
        border    : 2px solid @lightgrey;

        &:hover {
            background: transparent;
        }
    }

    &-white {
        background: #fff;
        color     : @black;
    }

    &-no-underline {
        text-decoration: none !important;
    }

    &.small-btn {
        font-size     : 12px;
        padding       : 7px 15px;
    }

    &:hover,
    &:focus,
    &:active {
        outline: none;
    }
    &.disabled,
    &:disabled{
        border: initial;
        background-color: @light-second;
        color: @dark-third;
        opacity: initial;
    }

    span{
        line-height: 1.5;
    }
}

.btn-white-arrow {
    background: #fff;
    color: black;

    span {
        &:after {
            content        : "";
            background     : url('/Resources/Graphics/right-arrow-icon.svg');
            background-size: 12px;
            width          : 12px;
            height         : 10px;
            display        : inline-block;
            margin-left    : 5px;
        }
        &.share-btn:after{
            background     : url('/Resources/Graphics/icon-share-arrow.svg');
        }

        &.orderby-btn:after {
            background: url('/Resources/Graphics/search-icon.svg');
        }

        &.filter-btn:after {
            background: url('/Resources/Graphics/search-icon.svg');
        }
    }
}

.btn-green-arrow {
    background: @accent-second;
    transition: background .2s ease-in;

    &:hover,
    &:focus,
    &:active {
        background: #656F71;
    }

    &:disabled{
        background: @light-second;
        color: @dark-third;

    }
}

.quantity-button {
    position        : relative;
    width           : 47px;
    height          : 50px;
    background-color: @backgroundgrey;
    border          : 1px solid @bordergrey;
    padding         : 0;
    border-radius   : 0;

    &--add {
        .icon:after {
            content   : "";
            width     : 12px;
            height    : 1px;
            background: @grey;
            position  : absolute;
            top       : 24px;
            left      : 17px;
        }

        .icon:before {
            content   : "";
            width     : 12px;
            height    : 1px;
            background: @grey;
            position  : absolute;
            top       : 24px;
            left      : 17px;
            transform : rotateZ(90deg);
        }
    }

    &--remove {
        .icon:after {
            content   : "";
            width     : 12px;
            height    : 1px;
            background: @grey;
            position  : absolute;
            top       : 24px;
            left      : 17px;
        }
    }

    &:hover {
        background-color: @bordergrey;
        outline         : none;
    }
}

.orderby-btn {
    padding: 16px 30px;
    display: flex;
    align-items: center;

    svg {
        margin-right: 10px;
    }
}

.filter-btn {
    padding: 16px 30px;
    display: flex;
    align-items: center;

    svg {
        margin-right: 10px;
    }
}