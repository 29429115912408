.teaserblock {
    position: relative;
    text-align: center;
    padding: 24px 0;

    a{
        &:hover {
            text-decoration: none;
        }
    }

    img{
        transition: opacity .2s ease-out;
        &:hover {
            opacity: 70%;
        }
    }

    &__heading {
        padding: 0;
        margin: 0;
    }

    .teasers {
        margin-left: -15px;
        margin-right: -15px;
    }

    .teaser {
        .teaser-inner {
            color: black;
            padding: 15px;
            padding-top: 30px;
        }

        h5 {
            margin: 10px 0 10px 0;

            @media (max-width: @screen-width-tablet) {
                line-height: 1.22;
            }
        }

        p {
            margin: 0 0 25px;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
            overflow: hidden;
        }
    }

    &.teaser-2 {
        .teasers {
            display: flex;
            flex-wrap: wrap;
        }

        .teaser {
            width: 100%;

            @media (min-width: @screen-width-tablet) {
                width: 50%;
            }

            h3 {
                @media (min-width: @screen-width-desktop) {
                    font-size: 30px;
                    line-height: 1.27;
                }
            }


        }
    }

    &.teaser-3 {
        .teasers {
            display: flex;
            flex-wrap: wrap;
        }

        .teaser {
            width: 100%;

            @media (min-width: @screen-width-tablet) {
                width: 50%;

                &:first-child {
                    width: 100%;
                }
            }

            @media (min-width: @screen-width-lg-desktop) {
                width: 33.3%;

                &:first-child {
                    width: 33.3%;
                }
            }

        }
    }

    &.teaser-4 {
        .teasers {
            display: flex;
            flex-wrap: wrap;
        }

        .teaser {
            width: 100%;

            @media (min-width: @screen-width-tablet) {
                width: 50%;
            }

            @media (min-width: @screen-width-lg-desktop) {
                width: 25%;
            }

            h3 {
                margin: 20px 0;
            }
        }
    }
}

.smallteasersblock {
    padding: 30px 0 20px 0;

    .row {
        margin: 0 -10px;
    }

    a {
        padding: 0 10px;

        @media (min-width: @screen-width-tablet) {
            padding: 5px 10px;
        }
    }

    &__inner {
        margin-bottom: 10px;
        display: flex;
        background: #fff;
        overflow: hidden;
    }

    &__image {
        width: 77px;
        height: 77px;

        img {
            min-width: 77px;
            height: 77px;
        }
    }

    &__content {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        align-items: center;
        height: 77px;
        padding-left: 15px;
        padding-right: 5px;

        @media (min-width: @screen-width-xxl-desktop) {
            padding-left: 20px;
        }

        h2 {
            width: 100%;
            font-size: 13px;
            font-weight: normal;
            line-height: 1.31;
            font-family: @font-family-sans-serif;
            letter-spacing: -0.37px;
            color: @grey;
            margin: 0 0 3px 0;
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;

            @media (min-width: @screen-width-tablet) and (max-width: @screen-width-xl-desktop) {
                font-size: 12px;
            }
        }

        &--inner {
        }
    }

    &__arrow {
        width: 100%;
        font-size: 16px;
        font-weight: 600;
        font-family: @font-family-sans-serif;
        letter-spacing: -0.46px;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;

        @media (min-width: @screen-width-tablet) and (max-width: @screen-width-xl-desktop) {
            font-size: 14px;
        }
    }
}
