.searchpage {
    padding-top: 30px;
    padding-bottom: 60px;

    .big-heading {
        text-align: center;
    }

    .tabs-header {
        justify-content: center;
    }

    .search-tabs-container {
        @media (min-width: @screen-width-tablet) {
            padding-top: 40px;
        }
    }

    .searchresult {
        ul {
            list-style: none;
        }

        &__heading {
            margin: 10px 0 2px 0;
        }

        &__loadmore {
            text-align: center;
        }

        &__header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding-top: 20px;
            flex-wrap: wrap;
            position: relative;
 
            p {
                margin: 0;

                @media (max-width:@screen-width-desktop) {
                    text-align: center;
                    width: 100%;
                }
            }

            .product-count {
                position: absolute;
                left: 50%;
                transform: translateX(-50%);
                top: 33px;
                font-family: @font-family-serif;
                font-size: 20px;
            }

            .right-side {
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;
                margin-top: 20px;
                position: absolute;
                top: -20px;
                right: 0;

                @media (max-width:@screen-width-desktop) {
                    align-items: flex-end;
                    width: 100%;
                    position: relative;
                    padding: 20px 0 0 0;
                    margin-top: 20px;
                    top: 0;
                }
                /*.orderby-dropdown {
                    @media (max-width:@screen-width-desktop) {
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 50%;
                    }
                }

                .searchfilter-btn {
                    @media (max-width:@screen-width-desktop) {
                        position: absolute;
                        top: 0;
                        right: 0;
                        width: calc(50% - 10px);
                    }
                }*/
            }

            .orderby span {
                margin: 0 10px 10px 0;

                @media (min-width:@screen-width-desktop) {
                    margin: 0 10px 0 0;
                }
            }
        }

        hr {

            @media (max-width:@screen-width-desktop) {
                margin-top: 10px;
            }
        }

        .teaserblock .teaser p {
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
        }
    }

    &__resulttext {
        text-align: center;
    }

    .searchfilter-btn {
        padding: 11px 20px;
        font-size: 16px;
        font-weight: 600;
        margin: 0 3px 0 10px;
        height: 44px;
    }

    &__loader {
        display: none;
        margin: auto;
        margin-top: 100px;
        border: 11px solid @light;
        border-top: 11px solid @accent;
        border-radius: 50%;
        width: 50px;
        height: 50px;
        animation: spin 2s linear infinite;
    }
    &__noproducts{
        text-align: center;
    }
}

.product-listing__collection {
    @media (max-width: @screen-width-tablet) {
        padding: 5px;
        margin: 0;
    }

    .product-list-item {
        &__image {
            height: 100px;
            padding-bottom: 0;
            margin-bottom: 0;
            width: 100px;
            flex: none;

            img {
                position: relative;
                height: auto;
            }



            @media (min-width: @screen-width-tablet) {
                width: auto;
            }
        }

        &__price {
            color: @fail;
        }

        @media (max-width: @screen-width-tablet) {
            width: 100%;
            -ms-flex: 0 0 100%;
            flex: 0 0 100%;
            max-width: 100%;

            &__content-container {
                display: flex;
                flex-direction: column;
                justify-content: center;
                padding: 0 10px;

                .variants {
                    padding: 5px 0;
                    justify-content: flex-start;
                }
            }

            &__inner {
                display: flex;

                a {
                    display: flex;
                }
            }

            &__content {
                padding: 0 20px;
            }

            &__brand,
            &__price,
            &__name {
                text-align: left;
            }

            &__name {
                margin: 0px 0 2px 0;
            }

            &__price {
                font-size: 10px;
            }
        }
    }
}