.filter-overlay {
    display: none;
    height: 100%;
    width: 100%;
    background-color: rgba(172, 171, 171, 0.5);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 0;
    z-index: 99;
}
.searchfilter-flyin {
    z-index: 100;
    position: fixed;
    top: 0;
    bottom: 0px;
    right: -100%;
    width: 90%;
    height: 100%;
    background: #fff;
    overflow: hidden;
    -webkit-transition: right 0.5s ease;
    -moz-transition: right 0.5s ease;
    -ms-transition: right 0.5s ease;
    -o-transition: right 0.5s ease;
    transition: right 0.5s ease;

    &.searchfilter-expanded {
        right: 0;
    }

    @media (min-width: 375px) {
        width: 325px;
    }

    &-closebtn {
        width: 16px;
        height: 16px;
        position: absolute;
        top: 15px;
        right: 15px;
        background: none;
        border: none;
        padding: 0;
        cursor: pointer;
    }

    &-top {
        height: 80%;
        padding: 30px 20px;

        @media (min-width: @screen-width-xl-desktop) {
            padding: 60px 30px;
        }
    }

    &-bottom {
        background: @backgroundgrey;
        padding: 35px 15px;
        position: absolute;
        bottom: 0;
        right: 0;
        text-align: center;
        width: 100%;
        display: flex;
        justify-content: space-evenly;

        .btn {
            padding: 12px 25px;
        }
    }

    .searchfilter-flyin-top {
        div.box {
            display: flex;
            flex-flow: row nowrap;
            align-items: center;

            .total-active-filters {
                display: inline-block;
                background-color: @green;
                border-radius: 50%;
                width: 27px;
                height: 27px;
                text-align: center;
                padding-top: 4px;
                font-size: 12px;
                margin-left: 8px;
                margin-bottom: 15px;
                
                @media (min-width: @screen-width-xl-desktop) {
                    margin-top: 6px;
                }
            }
        }
    }

    .slide-menu {
        &__slider {
            height:100%;
            li {
                display: flex;
                position: relative;
                padding-right: 2px;

                ul {
                    position: fixed;
                    bottom:0;
                    overflow-y:scroll;
                }

                label {
                    font-size: inherit;
                    font-weight: inherit;
                    letter-spacing: inherit;
                    margin: inherit;
                    line-height: inherit;

                    input[type=checkbox] {
                        visibility: hidden;
                        width: 20px;
                    }

                    input:checked ~ .styledcheck:after {
                        display: block;
                    }

                    input:checked ~ .styledcheck {
                        background-color: white;
                    }


                    .styledcheck {
                        position: absolute;
                        top: 16px;
                        left: 0;
                        height: 20px;
                        width: 20px;
                        background-color: #f6f6f6;
                        border: solid 1px #d6d6d6;
                        border-radius: 2px;

                        @media (min-width: @screen-width-desktop) {
                            top: 20px;
                        }

                        &:after {
                            content: "";
                            display: none;
                            margin: 4px;
                            width: 10px;
                            height: 10px;
                            background-color: black;
                            border-radius: 1px;
                        }
                    }
                }



                .active-filters {
                    position: relative;
                    /* right: 0px; */
                    right: 30px;
                    font-size: 13px;
                }
            }
        }
    }
}
