.splitblock {
    padding: 40px 0;

    .row {
        margin: 0;
        overflow: hidden;
    }

    &__image {
        padding: 0;
        min-height: 300px;
        background-size: cover;
        background-repeat: no-repeat;
        order: 1;
        background-position: center;

        @media (min-width: @screen-width-desktop) {
            min-height: 500px;
        }

        @media (min-width: @screen-width-lg-desktop ) {
            order: unset;
        }

        @media (min-width: @screen-width-xl-desktop) {
            min-height: 640px;
        }
    }

    &__content {
        padding: 0;
        background: #fff;
        display: flex;
        align-items: center;
        padding: 30px;

        @media (min-width: @screen-width-desktop ) {
            padding: 50px;
        }

        @media (min-width: @screen-width-xl-desktop ) {
            padding: 70px;
        }

        h2 {
            font-size: 20px;
            line-height: 1.16;
            letter-spacing: -1.09px;

            @media (min-width: @screen-width-desktop) {
                font-size: 38px;
            }
        }

        p {
            font-family: @font-family-sans-serif;
            font-size: 18px;
            font-weight: normal;
            line-height: 1.73;
            letter-spacing: -0.63px;

            @media (min-width: @screen-width-desktop ) {
                font-size: 22px;
            }
        }

        p a{
            text-decoration: underline;
        }

        .btn {
            margin-top: 15px;
            display: inline-block;
        }
    }
}
