.error-message-popup{
    display         : none;
    width           : 90%;
    height          : auto;
    border-radius   : 4px;
    box-shadow      : 0 0 10px 0 rgba(87, 86, 86, 0.2);
    background-color: #ffffff;
    padding         : 20px;
    position        : fixed;
    top             : 50%;
    left            : 50%;
    transform       : translate(-50%, -50%);
    text-align      : center;
    z-index         : 2000;
    min-height      : 250px;
    justify-content: space-between;
    flex-direction: column;
    align-items: center;

    @media (min-width: @screen-width-desktop) {
        width : 375px;
        height: 230px;
        top   : 30%;
    }

    &__enabled{
        display: flex;
    }
}