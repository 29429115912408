.search-flyout {
    z-index: 1012;
    display: none;
    background-color: @backgroundgrey;
    margin: 0 auto;
    position: absolute;
    max-width: 823px;
    min-height: 315px;
    // top: 408px;
    left: 0;
    right: 0;
    box-shadow: 0 0 10px 0 rgba(87, 86, 86, 0.2);

    @media (max-width: @screen-width-tablet) {
        top: 100%;
        min-height: 0px;
    }

    @media (min-width: @screen-width-tablet) {
        // top: 387px;
        // bottom: 0;
    }

    @media (min-width: @screen-width-lg-desktop) {
        // top: 330px;
    }

    &__inner {
        @media (max-width: @screen-width-tablet) {
            position: relative;
            height: 100%;
            padding-bottom: 40px;
            overflow-y: scroll;
            background-color: #F6F6F6;
        }
    }

    &__close {
        float: right;
        padding: 20px;

        @media (min-width: @screen-width-tablet) {
            display: none;
        }
    }

    &__products {
        padding: 15px;
        background-color: #F6F6F6;

        @media (min-width: @screen-width-tablet) {
            padding: 0px;
            margin: 30px;
        }
    }

    &__product-header {
        .sans-medium;
        width: 100%;
        display: flex;
        justify-content: space-between;
        font-size: @font-size-medium;

        @media (min-width: @screen-width-tablet) {
            font-size: @font-size-large;
            line-height: 1.4;
        }

        .flyout-search-link {
            color: @accent;
            cursor: pointer;
            text-decoration: underline;

            @media (min-width: @screen-width-tablet) {
                font-size: 16px;
            }
        }
    }

    .flyout-productresultlist {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        margin-top: 8px;

        .flyout-product {
            &.flyout__productitem {
                display: flex;
                background: white;
                margin-bottom: 10px;
                width: 100%;
                min-height: 60px;
                font-weight: normal;

                @media (min-width: @screen-width-tablet) {
                    width: 48%;
                }

                a {
                    font-family: @font-family-sans-serif;
                    text-decoration: none;
                    color: black;
                    display: flex;
                    align-items: center;
                    width: 100%;
                    padding: 6px;

                    @media (min-width: @screen-width-tablet) {
                        font-size: 16px;
                        line-height: 1.75;
                    }

                    img {
                        margin-right: 10px;
                    }

                    .flyout__productname {
                        margin-bottom: 3px;
                    }

                    .flyout__itemprice {
                        .sans-medium;
                        font-size: 11px;
                        line-height: 1.45;
                        color:@fail;

                        @media (min-width: @screen-width-tablet) {
                            font-size: 12px;
                            line-height: 1.5;
                        }
                    }
                }
            }
        }
    }

    .flyout-noproducts {
        display: none;
        justify-content: center;
        align-items: center;
        min-height: 100px;
    }

    &__pagescontainer {
        background-color: white;
        padding: 15px;
        display: none;

        @media (min-width: @screen-width-tablet) {
            padding: 30px;
        }

        &__pages {
            &__header {
                margin-bottom: 14px;
                font-size: @font-size-medium;
                .sans-medium;
        
                @media (min-width: @screen-width-tablet) {
                    font-size: @font-size-large;
                }
        
            }

            &__resultlist {
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;

                .flyout__pageitem {
                    margin-right: 5px;
                    width: 100%;

                    @media (min-width: @screen-width-tablet) {
                        width: calc(50% - 10px);
                    }

                    &__container {
                        display: flex;
                        align-items: center;
                        padding-bottom: 10px;

                        a {
                            padding-left: 11px;
                            color: black;
                            font-size: 14px;
                            line-height: 1.57;

                            @media (min-width: @screen-width-tablet) {
                                font-size: 16px;
                                line-height: 1.75;
                            }
                        }
                    }
                }
            }
        }
    }

    &__loader {
        display: none;
        margin: auto;
        margin-top: 100px;
        border: 11px solid @light;
        border-top: 11px solid @accent;
        border-radius: 50%;
        width: 50px;
        height: 50px;
        animation: spin 2s linear infinite;
    }

    @keyframes spin {
        0% {
            transform: rotate(0deg);
        }

        100% {
            transform: rotate(360deg);
        }
    }
}
