.dropdown {

    .dropdown__list {
        list-style    : none;
        padding       : 0;
        margin        : 0;
        width         : 100%;
        z-index       : 11;
        display       : flex;
        flex-direction: column;
        flex-wrap     : wrap;

        li {
            display: block;
            width  : 100%;
            order  : 2;

            a,
            button {
                width         : 100%;
                display       : block;
                text-align    : center;
                border-radius : 0;
                background    : @green;
                border        : 1px solid @green;
                font-size     : 16px;
                font-weight   : 600;
                letter-spacing: -0.34px;
                color         : #000;
                margin        : 0;
                padding       : 15px;
                transition    : background .25s ease-in;
            }

            &.current {
                order   : 1;
                position: relative;

                &:after {
                    content     : "";
                    width       : 10px;
                    height      : 10px;
                    border-top  : 2px solid #000;
                    border-right: 2px solid #000;
                    display     : block;
                    position    : absolute;
                    right       : 30px;
                    top         : 23px;
                    transform   : rotateZ(-44deg);
                    z-index     : 10;
                }

                &:before {
                    content   : "";
                    background: @green;
                    height    : 35px;
                    width     : 100%;
                    position  : absolute;
                    top       : 20px;
                    display   : block;
                }

                a {
                    background   : #fff;
                    border-color : @green;
                    border-radius: 30px;
                    z-index      : 10;
                    position     : relative;
                }
            }

            &:last-child {

                a,
                button {
                    padding      : 15px 15px 30px 15px;
                    border-radius: 0 0 30px 30px;
                }
            }
        }
    }

    &.closed {

        li:not(.current) {
            display: none;
        }

        li.current {
            &:after {
                top      : 19px;
                transform: rotateZ(135deg);
            }

            &:before {
                display: none;
            }
        }
    }
}

.orderby-dropdown {
    .dropdown {

        .dropdown__list {
            position: absolute;
            background-color: white;
            width: 200px;
            top: 20px;
            box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);

            @media (max-width: @screen-width-tablet) {
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
            }

            li {
                cursor: pointer;
                padding-top: 3px;
                padding-bottom: 7px;
                padding-left: 10px;

                a {
                    background: initial;
                    font-weight: 300;
                    font-size: 14px;
                    letter-spacing: -0.4px;
                    border: none;
                    text-align: left;
                    padding: 5px 0;
                    border-color: white;

                    .outer-circle {
                        background: white;
                        border-radius: 50%;
                        height: 20px;
                        width: 20px;
                        position: relative;
                        border: 1px solid @bordergrey;
                        top: 4px;
                        display: inline-block;
                        margin-right: 5px;
                    }

                    .inner-circle {
                        position: absolute;
                        background: black;
                        border-radius: 50%;
                        height: 12px;
                        width: 12px;
                        top: 50%;
                        left: 50%;
                        margin: -6px 0px 0px -6px;
                    }
                }

                &:hover, &.selected {
                    background: #F4FAF4;
                }
            }

            li.current {
                &:after {
                    top: 20px;
                }

                &:before {
                    background: white;
                }
            }
        }

        &.closed {
            li.current:after {
                top: 16px;
            }
        }
    }

    .mobile-dropdown {
        @media (min-width:@screen-width-desktop) {
            display: none;
        }
    }
}