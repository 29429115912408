.wishlist {
    z-index           : 100;
    position          : fixed;
    top               : 0;
    bottom            : 0px;
    right             : -100%;
    width             : 90%;
    height            : 100%;
    background        : @backgroundgrey;
    overflow          : hidden;
    overflow-y        : scroll;
    -webkit-transition: right 0.5s ease;
    -moz-transition   : right 0.5s ease;
    -ms-transition    : right 0.5s ease;
    -o-transition     : right 0.5s ease;
    transition        : right 0.5s ease;

    &.black-scrollbar::-webkit-scrollbar-thumb {
        background-color: @brand-primary;
    }

    &.wishlist-expanded {
        right: 0;
    }

    @media (min-width: 375px) {
        width: 325px;
    }

    @media (min-width: @screen-width-desktop) {
        width: 525px;
    }

    @media (min-width: @screen-width-xl-desktop) {
        width: 605px;
    }

    &-top {
        background: @backgroundgrey;
        height    : 80%;
        padding   : 30px 20px;

        @media (min-width: @screen-width-xl-desktop) {
            padding: 60px 30px;
        }
    }

    &-closebtn {
        width        : 16px;
        height       : 16px;
        position     : absolute;
        top          : 15px;
        right        : 15px;
        background   : none;
        border       : none;
        padding      : 0;
        cursor       : pointer;
        border-radius: 0;
    }

    .product-listing__collection {
        margin-right: -5px;
        margin-left : -5px;
        padding     : 5px;

        .row {
            margin-right: 0px;
            margin-left : 0px;

            .product-list-item {
                flex      : 0 0 100%;
                max-width : 100%;
                min-height: 125px;
                background-color:#fff;

                &__inner {
                    display: flex;
                    padding: 20px 15px 20px 15px;

                    a {
                        display: flex;
                    }
                }

                &__image {
                    height        : 48px;
                    padding-bottom: 0;
                    margin-bottom : 0;
                    width         : 50px;
                }

                &__content-container {
                    display        : flex;
                    flex-direction : column;
                    justify-content: center;

                    .variants {
                        order          : 2;
                        padding        : 10px 0 0 10px;
                        justify-content: flex-start;
                        display        : flex;

                        .variant-color {
                            width        : 14px;
                            height       : 14px;
                            border-radius: 50%;
                            margin       : 0 3px;
                            border       : none;
                        }

                        .variant-custom {
                            width : 14px;
                            height: 14px;
                            margin: -1px 3px;
                        }

                        span {
                            margin        : 7px 0 0px 7px;
                            font-size     : 12px;
                            font-style    : italic;
                            line-height   : 0.2;
                            letter-spacing: -0.34px;
                            color         : @grey;
                        }
                    }

                }

                &__content {
                    padding: 0 10px;

                }

                &__brand,
                &__price,
                &__name {
                    text-align: left;
                }

                &__brand {
                    /*font-size: 12px;*/
                }

                &__name {
                    /*font-size: 13px;*/
                }

                &__price {
                    /*font-size: 12px;*/
                }
            }
        }

        &__buybtn {
            display: none;
        }
    }
}