.genericblock {
    picture img {
        margin: 30px auto;
        display: block;
        max-width: 1070px;
        width: 100%;
    }

    &-text {
        width: 100%;
        margin: 0 auto;

        @media (min-width: @screen-width-tablet ) {
            width: 605px;
        }

        ul, ol {
            padding: 0 0 0 15px;

            li {
                padding-bottom: 10px;
            }
        }

        p a {
            text-decoration: underline;
        }
    }
}
