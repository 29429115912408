@font-face {
  font-family: "Neufile Grotesk Extended";
  src: url("../Fonts/neufile-grotesk-extended-regular.woff2") format("woff2"),
    url("../Fonts/neufile-grotesk-extended-regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Neufile Grotesk Extended";
  src: url("../Fonts/neufile-grotesk-extended-regular-italic.woff2")
      format("woff2"),
    url("../Fonts/neufile-grotesk-extended-regular-italic.woff") format("woff");
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: "Neufile Grotesk Extended";
  src: url("../Fonts/neufile-grotesk-extended-bold.woff2") format("woff2"),
    url("../Fonts/neufile-grotesk-extended-bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Neufile Grotesk Extended";
  src: url("../Fonts/neufile-grotesk-extended-bold-italic.woff2")
      format("woff2"),
    url("../Fonts/neufile-grotesk-extended-bold-italic.woff") format("woff");
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: "Druk Wide";
  src: url("../Fonts/druk-wide-medium.woff2") format("woff2"),
    url("../Fonts/druk-wide-medium.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Druk Wide";
  src: url("../Fonts/druk-wide-medium-italic.woff2") format("woff2"),
    url("../Fonts/druk-wide-medium-italic.woff") format("woff");
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: "Noe Display Medium Italic";
  src: url("../Fonts/Noe-Display-Medium-Italic.woff2") format("woff2"),
    url("../Fonts/Noe-Display-Medium-Italic.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Noe Display Medium Italic";
  src: url("../Fonts/Noe-Display-Medium-Italic.woff2") format("woff2"),
    url("../Fonts/Noe-Display-Medium-Italic.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
