.productlistingblock {
    padding: 20px 0;

    @media (min-width: @screen-width-xxl-desktop) {
        padding: 40px 0;
    }

    .container {
        position: relative;

        .row {
            margin-left: 0;
        }
    }

    &__row {
        margin-left: 0;
        display: flex;
    }

    &__heading {
        margin: 0 0 10px 0;
        flex-basis: auto;

        @media (max-width: @screen-width-tablet) {
            font-size: 24px;
        }
    }

    &__header {
        @media (min-width: @screen-width-tablet) {
            display: flex;
            justify-content: space-between;
        }

        .button {
            width: 240px;
        }
    }

    &__catimage {
        display: none;

        @media (min-width: 1200px) {
            display: block;
            min-width: 503px;
            margin: 15px 0;
            padding-right: 30px;

            img {
                object-fit: cover;
                height: 363px;
            }
        }

        @media (min-width: @screen-width-xxl-desktop) {
            img {
                height: 403px;
            }
        }
    }

    .product-listing__collection {
        .product-list-item {
            padding: 15px;
            margin: 0;
            background-color: transparent;

            &__price {
                color: @fail;
            }

            @media (max-width: @screen-width-tablet) {
                flex-basis: auto;
            }

            &__gutter {
                background-color: transparent;
                position: relative;
                text-align: left;
                padding-bottom: 10px;

                @media (min-width: @screen-width-tablet) {
                    margin: 0;
                    width: 100%;
                }

                @media (min-width: @screen-width-lg-desktop) {
                    width: 100%;
                }
            }

            &__name {
/*                display: -webkit-box;
                -webkit-line-clamp: 1;
                -webkit-box-orient: vertical;*/
                overflow: hidden;
            }

            &__inner {
                @media (max-width: @screen-width-tablet) {
                    /*padding: 60px 15px 20px 15px;*/
                    display: initial;

                    a {
                        display: initial;
                    }
                }
            }

            &__image {
                @media (max-width: @screen-width-tablet) {
                    padding-bottom: 100%;
                    margin-top: 20px;
                    width: auto;
                }
            }

            &__content {
                padding: 0;
            }

            &__content-container {
                padding: 6px 0 0 0;

                @media (min-width: @screen-width-desktop ) {
                    /*min-height: 140px;*/
                }
            }

            .variants {
                @media (max-width: @screen-width-tablet) {
                    display: flex;
                    justify-content: left;
                    padding: 10px 0;
                }
            }

            &__brand,
            &__price,
            &__name {
                @media (max-width: @screen-width-tablet) {
                    text-align: left;
                }
            }

            &__brand {
                @media (max-width: @screen-width-tablet) {
                    font-size: 15px;
                }
            }

            &__name {
                @media (max-width: @screen-width-tablet) {
                    font-size: 14px;
                }
            }

            &__price {
                @media (max-width: @screen-width-tablet) {
                    font-size: 16px;
                }
            }
        }
    }

    &__slideshow {
        position: relative;
        padding-left: 0;
        margin-left: -10px;
        overflow: hidden;

        @media (min-width: @screen-width-xl-desktop) {
            margin-left: -15px;
        }

        .tns-nav {
            display: none;
        }
    }

    &__slideshow-btn {
        top: 135px;
        position: absolute;
        width: 100%;
        display: none;

        @media (min-width: @screen-width-desktop) {
            top: 40%;
            left: -12px;
            display: block;
        }

        .next,
        .prev {
            position: absolute;
            top: 65px;
            width: 40px;
            height: 40px;
            box-shadow: 0 0 10px 0 #d6d6d6;
            background-color: #ffffff;
            border-radius: 50%;
            box-shadow: 0px 0px 10px #A5A3A0;
            padding: 0;


            @media (min-width: @screen-width-desktop) {
                top: 0;
            }

            &:before {
                content: "";
                background-image: url(/Resources/Graphics/arrow-left-new.svg);
                background-repeat: no-repeat;
                background-size: 10px;
                width: 10px;
                height: 18px;
                position: absolute;
                top: 12px;
                left: 13px;
                transform: rotateZ(180deg);
            }
        }

        .next {
            right: 0;

            @media (min-width:@screen-width-xl-desktop) {
                right: 5px;
            }

            &:before {
                left: 16px;
                transform: none;
            }
        }

        .prev {
            left: 8px;

            @media (min-width:@screen-width-tablet) {
                left: 15px;
            }

            @media (min-width:@screen-width-xl-desktop) {
                left: 20px;
            }
        }
    }

    &__top-btn {
        position: absolute;
        top: 0px;
        right: 15px;

        @media (max-width:@screen-width-desktop) {
            display: none;
        }
    }

    &__bottom-btn {
        @media (min-width:769px) {
            display: none;
        }

        display: flex;
        justify-content: center;
        align-items: center;
        padding-top: 20px;
    }
}
