.imagewithbuyblock {
    padding: 30px 0;

    @media (min-width: @screen-width-desktop) {
        padding: 70px 0 50px;
    }

    @media (min-width: @screen-width-xl-desktop) {
        max-width: 1050px;
        margin: 0 auto;
    }

    &__cart {
        position: relative;

        &:after {
            content: "";
            background-image: url('/Resources/Graphics/cart-circle.svg');
            background-repeat: no-repeat;
            width: 36px;
            height: 36px;
            position: absolute;
            right: 11px;
            bottom: 16px;
            background-color: white;
            -moz-border-radius: 50%;
            -webkit-border-radius: 50%;
            border-radius: 50%;
            background-size: 20px;
            background-position: center;

            @media (min-width: @screen-width-desktop) {
                right: 15px;
                bottom: 24px;
            }
        }
    }

    &__image {
        padding: 0 5px;
        position: relative;

        img {
            width: 100%;
        }

        &.big-image {
            @media (min-width: @screen-width-xl-desktop) {
                padding-right: 25px;

                &:after {
                    right: 35px;
                }
            }
        }

        &.small-image {
            /*@media (min-width: @screen-width-lg-desktop) {
                height: 453px;
            }

            @media (min-width: @screen-width-xxl-desktop) {
                height: 578px;
            }*/
        }
    }

    &__quote {
        padding: 20px 5px;
        padding-top: 20px;
        display: none;

        @media (min-width: @screen-width-xl-desktop) {
            display: block;
        }

        &--mobile {
            padding: 0 5px;
            padding-top: 20px;

            @media (min-width: @screen-width-tablet) {
                padding: 20px 100px;
            }

            @media (min-width: @screen-width-lg-desktop) {
                padding: 20px 170px;
            }

            @media (min-width: @screen-width-xl-desktop ) {
                display: none;
            }
        }
    }

    .modal-container {
        background: @backgroundgrey;
        padding: 20px;
        overflow-y: auto;
        height: 100%;
        width: 100%;

        .row {
            flex-direction: row;
            height: auto;
            justify-content: center;
        }

        @media (min-width: @screen-width-xl-desktop) {
            width: 1000px;
            height: 555px;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }

        &__heading {
            text-align: center;
            padding: 15px 30px 0 20px;
        }
    }

    .modal-overlay {
        display: none;
        background-color: rgba(172, 171, 171, 0.4);
        position: fixed;
        height: 100%;
        width: 100%;
        top: 0;
        left: 0;
    }

    &__modal-closebtn {
        position: absolute;
        top: 20px;
        right: 20px;
        padding: 0;
        border-radius: 0;
        background: none;
    }
}
