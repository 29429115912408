.categorypage {
    padding-top: 20px;
    padding-bottom: 60px;

    .hero {
        margin: 0 auto;

        @media (min-width: 992px) {
            max-width: 930px;
        }

        @media (min-width: 1200px) {
            max-width: 1110px;
            margin-bottom: 15px;
        }

        @media (min-width: @screen-width-xxl-desktop) {
            max-width: 1410px;
        }
    }

    .big-heading {
        text-align: center;
        margin: 0 0 15px 0;

        @media (min-width: @screen-width-desktop) {
            padding-top: 40px;
            margin: 0 0 15px 0;
        }
    }

    .medium-heading {
        text-align: center;
    }

    &-text {
        max-width: 605px;
        margin: 0 auto;
        text-align: center;

        @media (min-width: @screen-width-desktop) {
            padding-bottom: 20px;
        }

        p {
            margin: 0px
        }
    }

    &__header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        position: relative;

        p {
            margin: 0;

            @media (max-width:@screen-width-desktop) {
                text-align: center;
            }
        }

        .right-side {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            position: absolute;
            top: 0;
            right: 0;

            @media (max-width:@screen-width-desktop) {
                align-items: flex-end;
                width: 100%;
                position: relative;
                padding: 20px 0 38px 0;
                margin-top: 20px;
            }

            .orderby-dropdown {
                @media (max-width:@screen-width-desktop) {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 50%;
                }

                &.filter-dropdown {
                    @media (max-width:@screen-width-desktop) {
                        left: inherit;
                        right: 0;
                    }
                }
            }

            .categorypage__filter-btn {
                @media (max-width:@screen-width-desktop) {
                    right: 0;
                    position: absolute;
                    top: 0;
                    width: calc(50% - 10px);
                }
            }

            .dropdown {
                margin-left: 10px;
            }
        }

        .orderby span {
            margin: 0 10px 10px 0;

            @media (min-width:@screen-width-desktop) {
                margin: 0 10px 0 0;
            }
        }
    }

    &__filter-btn {
        padding: 11px 20px;
        font-size: 16px;
        font-weight: 600;
        margin: 0 3px 0 10px;
        height: 44px;
    }

    .parametrics-editor {
        text-align: center;
        background: #fff;
        padding: 30px;
        margin-bottom: 40px;

        .parametrics-table {
            tr {
                padding-bottom: 4px;
            }
        }

        select {
            margin-bottom: 10px;
        }

        button {
            margin-top: 10px;
        }
    }

    &__loadmore-wrapper {
        padding: 20px 0;
        text-align: center;
    }

    &__tabs {
        .tabs-header {
            justify-content: center;
        }
    }
    &__noproducts{
        text-align: center;
        padding-top: 50px;
    }
}
