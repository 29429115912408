.menu {
    a {
        color: #fff;
    }
}

.pagemenu {
    padding: 10px 0 40px 0;
    margin: 0;
    list-style: none;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;

    @media (min-width: @screen-width-tablet) {
        padding: 10px 0 70px 0;
    }

    li {
        margin: 0 4px 10px 4px;
        display: inline-flex;

        @media (min-width: @screen-width-tablet) {
            margin: 0 4px;
        }

        a {
            .sans-medium;
            border-radius: 58px;
            border: solid 1px @light-second;
            background: #fff;
            font-size: 16px;
            text-align: center;
            padding: 12px 20px;
            transition: all .2s ease-in;
            height: 44px;

            @media (max-width:@screen-width-tablet) {
                padding: 8px 20px;
                font-size: 11px;
                height: 32px;
            }
        }

        &.active,
        &:hover {
            a {
                border          : solid 1px @accent;
                background: @accent;
                color           : #fff;
            }
        }
    }
}