.footer {
    position: relative;
    z-index: 30;
    
    @media print {
        display: none;
    }

    h2,
    h3 {
        font-family: @font-family-sans-serif;
        font-weight: 400;
        font-size: 12px;
        line-height: 1.5;
        color: @dark-second;
        text-transform: uppercase;
    }

    .block {
        width: 100%;

        @media (min-width: @screen-width-desktop) {
            width: 50%;
        }

        @media (min-width: @screen-width-lg-desktop) {
            width: 16%;

            &.textblock{
                width: 50%;
            }
        }


    }

    &-top-section {
        background: @light;
        padding: 20px 20px 40px 20px;

        @media (min-width: @screen-width-tablet) {
            padding: 60px 0;
        }

        .row {
            justify-content: center;
        }
    }

    &-content {
        padding: 20px 0 0 0;

        @media (min-width: @screen-width-tablet) {
            display: flex;
            flex-wrap: wrap;
        }
    }

    &-aboutcolorama {
        text-align: center;
        font-size: 16px;
        line-height: 28px;

        @media (min-width: @screen-width-tablet ) {
            text-align: left;
        }
    }

    &-bottom-section {
        background: #fff;
        padding: 20px;

        .payment_gateways {
            display: flex;
            justify-content: space-around;
            margin: 0 auto;
            width: 100%;
            align-items: center;

            @media (min-width: @screen-width-tablet) {
                justify-content: center;
         
            }

            .payment_gateway__block {
                @media (min-width: @screen-width-tablet) {
                    display: block;
                    margin-right: 60px;
                }
            }
        }
    }

    &-copyright {
        text-align: center;
        font-size: 14px;
        padding-top: 8px;
        padding-bottom: 10px;
        color: @dark-second;
        margin: 0 auto;

        @media (min-width: @screen-width-tablet) {
            font-size: 16px;
            position: absolute;
            padding-top: 10px;        
        }
    }

    .stayconnectedblock {
        @media (max-width: @screen-width-tablet) {
            .stay-connected-block-heading {
                text-align: center;
            }
        }
    }
}
