.customerdetailspage {
    padding-bottom: 40px;

    @media (min-width: @screen-width-lg-desktop) {
        padding-bottom: 80px;
    }

    h1 {
        text-align: center;
    }

    .form-group {
        margin: 10px 0;

        input[type="number"] {
            border-radius: 4px;
            border       : solid 1px #d6d6d6;
            background   : #F6F6F6;
            padding      : 15px;
        }

        &__checkbox {
            display      : flex;
            padding      : 15px;
            border-bottom: @backgroundgrey 2px solid;
            margin       : 0 -15px;

            span {
                padding-left: 5px;
                display     : inline-block;
                font-size   : 14px;
            }
        }
    }

    #myoptions-form,
    #mydetails-form,
    #update-credentials-form {
        background: #fff;
        padding   : 5px 15px 0 15px;
        margin    : 40px auto 0 auto;
        max-width : 670px;

        h2 {
            border-bottom: @backgroundgrey 3px solid;
            text-align   : center;
            margin       : 0 -15px 0px -15px;
            padding      : 2px 0 8px 0px;

            @media (min-width: @screen-width-lg-desktop) {
                font-size: 20px;
            }
        }

        label {
            display: block;
        }

        .credentials-btn {
            margin-top   : 17px;
            margin-bottom: 17px;

            @media (min-width: @screen-width-desktop) {
                padding: 13px 50px;
            }
        }
    }
}