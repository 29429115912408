.cartpage {
    padding: 40px 23px;

    @media (min-width: @screen-width-desktop) {
        padding: 70px 0;
    }

    &__header {
        text-align: center;
        background: #fff;
        padding: 8px 0px 9px 0px;
        max-width: 840px;
        margin: 0 auto 2px auto;
    }

    .big-heading {
        text-align: center;
    }

    .cart__info {
        padding: 0 0 20px 0;
        display: flex;
        justify-content: center;

        @media (min-width: @screen-width-desktop) {
            padding: 20px 0;
        }

        .shipping:before {
            background-size: 26px;
            width: 26px;
            height: 26px;
        }

        .pay:before {
            background-size: 26px;
            width: 26px;
            height: 20px;
        }
    }

    &__greenbox {
        max-width: 840px;
        margin: 0 auto;
        background: rgba(54, 179, 126, 0.2);
        display: flex;
        padding: 15px;
        padding-top: 0px;
        flex-direction: column;
        text-align: center;

        @media (min-width: @screen-width-tablet) {
            flex-direction: row;
            text-align: initial;
            justify-content: space-between;
            align-items: center;
            padding-top: 15px;
        }

        button{
            text-decoration: underline;
            background: initial;
            color:@accent-second;
            font-size: 11px;
            line-height: 1.45;

            @media (min-width: @screen-width-tablet) {
                font-size: 12px;
                line-height: 16px;
            }
        }
    }

    .coupon-modal {
        width: 90%;
        height: auto;
        border-radius: 4px;
        box-shadow: 0 0 10px 0 rgba(87, 86, 86, 0.2);
        background-color: #ffffff;
        padding: 20px;
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        text-align: center;
        z-index: 1020;

        @media (min-width: 321px) {
            padding: 40px;
        }

        @media (min-width: @screen-width-desktop) {
            width: 542px;
            min-height: 283px;
        }

        p {
            .sans-medium;
            text-align: center;
            font-size: 16px;
            line-height: 1.5;
            letter-spacing: -0.46px;

            @media (min-width: @screen-width-desktop) {
                padding: 0 75px;
            }
        }

        label {
            text-align: left;
            display: block;
        }

        input {
            border-radius: 4px;
            border: solid 1px @bordergrey;
            background: @backgroundgrey;
            padding: 15px;
            font-size: 16px;
            width: 100%;
        }

        .btn-green {
            margin-top: 20px;
        }

        .coupon-invalid {
            padding-top: 20px;
            font-size: 12px;
            color: @brand-danger;
            font-style: italic;
        }

        .btn-close-coupon {
            width: 16px;
            height: 16px;
            position: absolute;
            top: 15px;
            right: 15px;
            border: none;
            padding: 0;
            cursor: pointer;
            border-radius: 0;
            background: none;
        }
    }

    .coupon-active {
        display: block;
        font-size: 13px;
        font-style: italic;
        color: @grey;
    }

    .btn-remove-coupon {
        border: none;
        background: url(/Resources/Graphics/trash.svg);
        background-size: 12px;
        background-repeat: no-repeat;
        padding: 0 0 0 15px;
        font-size: 13px;
        letter-spacing: -0.37px;
        border-radius: 0;
        margin-top: 6px;
    }

    .cart-modal-container {
        position: fixed;
        z-index: 1010;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: rgba(172, 171, 171, 0.4);
        display: none;
    }

    &__totals {
        .moms {
            font-size: 11px;
            line-height: 1.45;
            padding-bottom: 3px;

            @media (min-width: @screen-width-tablet) {
                font-size: 12px;
                line-height: 1.5;
                text-align: right;
            }
        }

        .total-price {
            font-size: 14px;
            line-height: 1.57;
            .sans-medium;

            @media (min-width: @screen-width-tablet) {
                font-size: 16px;
                line-height: 1.5;
                text-align: right;
            }
        }
    }

    &__goback-btn {
        line-height: 1.25;
        padding: 0;
        border-radius: 0;
        position: relative;
        top: 5px;

        svg{
            transform: rotate(270deg);
            fill:@accent;
            position: relative;
            top: -2px;
          }
    }

    &__payment {
        max-width: 670px;
        margin: 0 auto;
        padding: 40px 0;
        display: none;
    }

    &__sharecart-container {
        max-width: 670px;
        margin: 0 auto;
        position: relative;
        padding-bottom: 15px;
        height: 23px;
    }

    &__sharecart {
        position: absolute;
        top: -27px;
        right: -10px;
        border-radius: 4px;
        background-color: initial;
        padding: 1px 7px;
        border: 1px solid black;

        @media (min-width: @screen-width-tablet) {
            top: -50px;
            right: 0;
        }

        span {
            .sans-medium;
            padding-right: 2px;
            color:@accent;
            font-size: 11px;
            line-height: 1.45;
        }
    }
}

.cartpage-header {
    .js-toggle-mobilenav-flyin {
        display: none;
    }

    .navigation-container {
        @media (max-width: @screen-width-tablet) {
            &__customer-profile{
                margin-left: auto;
            }
            .navigation-quicklink{
                span{
                    display: none;
                }
            }
        }
        a {
            color:@accent;
            font-size: 11px;

            @media (min-width: @screen-width-tablet) {
                font-size: 16px;
            }
        }
        &__customer-profile{
            @media (min-width: @screen-width-desktop) {
                font-size: 12px;
                display: flex;
                align-content: center;
                flex-direction: column;

                img{
                    margin:auto;
                }
                span{
                    padding-top: 3px;
                }
            }
        }

        &__logotype{
            width: 105px;
            position: absolute;
            margin-left: auto;
            margin-right: auto;
            left: 0;
            right: 0;
            top: initial;
            text-align: center;
        }
    }
    .navigation-container .menu{
        display: none;
    }
}

.cart {

    .customer-details {
        max-width: 670px;
        margin: 0 auto;
        margin-bottom: 30px;

        @media (min-width: @screen-width-desktop) {
            display: flex;
            align-items: flex-start;
            flex-wrap: wrap;
        }

        &__property {
            margin-bottom: 15px;
            flex-basis: 33%;

            .label {
                color: @grey;
            }
        }
    }

    .lineitem-collection {
        max-width: 840px;
        margin: 0 auto;

        .cart__productitem {
            margin-bottom: 2px;
        }
    }

    .delivery-methods {
        max-width: 840px;
        margin: 0 auto;
        padding: 40px 0px;

        @media (min-width: @screen-width-desktop) {
            padding: 60px 0px;
        }

        &__header {
            text-align: center;
            background: #fff;
            padding: 8px 0px 9px 0px;
            max-width: 840px;
            margin: 0 auto 2px auto;
        }

        .delivery-method-collection {
            position: relative;

            .loaderWrapper {
                display:none;
                position: absolute;
                width: 100%;
                height: 100%;
                background: rgba(48,48,48,0.2);
                z-index: 1;
                padding: 25% 45%;
            }

            .delivery-method-container {
                margin-bottom: 2px;
                padding: 15px;
                background: #fff;
                cursor: pointer;

                &.selected {
                    border: 2px solid black;
                }


                .delivery-method {
                    display: flex;
                    flex-direction: row;
                    justify-content: start;
                    align-items: baseline;

                    label {
                        .sans-medium;
                        font-size: 14px;
                        line-height: 1.57;

                        @media (min-width: @screen-width-tablet) {
                            font-size: 16px;
                            line-height: 1.5;
                        }
                    }

                    &__radio {
                        width: 0px;
                        display: none;
                    }

                    &__name {
                        flex: 1;
                    }

                    &__price {
                        .sans-medium;
                    }
                }

                .pickup {
                    padding-top: 10px;
                    position: relative;

                    &:after {
                        content: "";
                        background: url(/Resources/Graphics/arrow-left.svg);
                        background-size: 9px;
                        background-repeat: no-repeat;
                        position: absolute;
                        right: 20px;
                        top: 26px;
                        display: block;
                        width: 11px;
                        height: 17px;
                        transform: rotateZ(-90deg);
                        pointer-events: none;
                    }

                    #pickup-dropdown {
                        z-index: 2;
                    }

                    select:focus, select:active {
                        border: 2px solid #b5d9b5;
                        outline: none;
                    }
                }
            }
        }
    }

    &__info {
        display: flex;
        flex-wrap: wrap;

        .pay,
        .shipping {
            .sans-medium;
            font-size: 10px;
            display: flex;
            align-items: center;
            padding-bottom: 10px;

            @media (min-width: @screen-width-desktop) {
                font-size: 13px;
            }
        }

        .pay {
            margin-right: 10px;

            &:before {
                content: "";
                background: url(/Resources/Graphics/pay-icon.svg);
                background-size: 18px;
                background-repeat: no-repeat;
                width: 18px;
                height: 14px;
                display: block;
                margin-right: 5px;
                position: relative;
                top: 1px;
            }
        }

        .shipping {
            &:before {
                content: "";
                background: url(/Resources/Graphics/shipping-icon.svg);
                background-size: 18px;
                background-repeat: no-repeat;
                width: 18px;
                height: 18px;
                display: block;
                margin-right: 5px;
                position: relative;
                top: -1px;
            }
        }
    }

    &__productitem {
        display: flex;
        background: #fff;
        margin-bottom: 6px;
        padding: 15px 40px 15px 15px;
        position: relative;
    }

    &__productitem__container {
        padding-left: 15px;

        @media (min-width: @screen-width-tablet) {
            width: 260px;
        }
    }

    &__productimage {
        width: 60px;
        height: 60px;

        @media (min-width: @screen-width-desktop) {
            width: 70px;
            height: 70px;
        }
    }

    &__productname {
        .sans-medium;
        margin: 0 0 2px 0;
        font-size: 14px;
        line-height: 1.57;
        margin: 0 0 2px 0;
        color: black;

        @media (min-width: @screen-width-tablet) {
            font-size: 16px;
            line-height: 1.5;
        }
    }


    &__partno,
    &__colorinfo,
    &__itemprice,
    &__shipping,
    &__itemscount {
        .sans-regular;
        font-size: 11px;
        line-height: 1.45;
        color: @accent-second;
        opacity: 0.6;

        @media (min-width: @screen-width-tablet) {
            font-size: 12px;
            line-height: 1.5;
        }
    }

    &__itemprice__nosale {
        font-weight: normal;
        text-decoration: line-through;
        margin-right: 5px;
    }

    &__itemprice__discounted {
        color: @pink;
    }

    &__quantity-btns {
        display: flex;
        align-items: center;
        margin-top: 20px;

        @media (min-width: @screen-width-desktop) {
            position: absolute;
            top: 10px;
            right: 180px;
        }

        .quantity-button {
            width: 30px;
            height: 46px;
            border: none;
            background-color: @background;

            @media (min-width: @screen-width-tablet) {
                height: 50px;
            }

            &:first-child {
                border-radius: 4px 0 0 4px;
            }

            &:last-child {
                border-radius: 0 4px 4px 0;
            }

            .icon {
                position: absolute;
                top: 0px;
                left: -8px;
            }
        }

        .quantity-input {
            width: 25px;
            height: 46px;
            border: none;
            background-color: @background;

            @media (min-width: @screen-width-tablet) {
                height: 50px;
            }
        }
    }

    &__removeproduct {
        border: none;
        background: url('/Resources/Graphics/trash.svg');
        background-size: 16px;
        background-repeat: no-repeat;
        width: 16px;
        height: 16px;
        padding: 0;
        position: absolute;
        right: 15px;
        border-radius: 0;
        top: 15px;

        @media (min-width: @screen-width-tablet) {
            bottom: 45px;
            top: initial;
            background-size: 20px;
            width: 20px;
            height: 20px;
        }
    }

    &__productTotal {
        .sans-medium;
        font-size: 11px;
        line-height: 1.45;
        color: @accent-second;
        position: absolute;
        right: 15px;
        bottom: 20px;

        @media (min-width: @screen-width-tablet) {
            font-size: 12px;
            line-height: 1.5;
            right: 95px;
            bottom: 45px;
        }
    }
}