.slide-menu {
    transition: transform .3s ease-in-out;
    will-change: transform;
    width: 320px;
    max-width: 100%;
    height: 67vh;
    top: 0;
    right: 0;
    display: none;
    overflow: hidden;
    box-sizing: border-box;
    transform: translateX(0);
    overflow-y: auto;
    z-index: 1000;

    @media (min-width: 375px) {
        height: 70vh;
    }

    @media (min-width:@screen-width-desktop) and (max-width: 800px) {
        height: 75vh;
    }

    @media (min-width: @screen-width-xxxl-desktop) {
        height: 75vh;
    }

    a {
        cursor: pointer;
    }

    .slide-menu__slider {
        transition: transform .3s ease-in-out;
        will-change: transform;
        width: 100%;
        transform: translateX(0);
    }

    ul {
        position: relative;
        width: 100%;
        margin: 0;
        padding-left: 0;
        list-style: none;

        a {
            display: block;
            text-transform: capitalize;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
        }

        li {
            padding: 13px 0;
            border-bottom: 1px solid #F0F0F0;
        }

        ul {
            position: absolute;
            top: 0;
            left: 100%;
            display: none;
        }
    }

    &__backlink {
        font-weight: 600;
        position: relative;
        padding-left: 20px;

        &:before {
            content: "";
            background: url(/Resources/Graphics/right-arrow-icon.svg);
            background-repeat: no-repeat;
            background-size: 12px;
            width: 12px;
            height: 10px;
            position: absolute;
            left: 0;
            top: 5px;
            transform: rotateZ(180deg);
        }
    }
}