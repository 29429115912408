.minicart {
    z-index: 100;
    position: fixed;
    top: 0;
    bottom: 0px;
    right: -100%;
    width: 90%;
    height: 100%;
    background: @backgroundgrey;
    overflow: hidden;
    -webkit-transition: right 0.5s ease;
    -moz-transition: right 0.5s ease;
    -ms-transition: right 0.5s ease;
    -o-transition: right 0.5s ease;
    transition: right 0.5s ease;

    &.minicart-expanded {
        right: 0;
    }

    @media (min-width: 375px) {
        width: 325px;
    }

    @media (min-width: @screen-width-desktop) {
        width: 525px;
    }

    @media (min-width: @screen-width-xl-desktop) {
        width: 605px;
    }

    &-top {
        background: @backgroundgrey;
        height: 80%;
        padding: 30px 20px;

        @media (min-width: @screen-width-xl-desktop) {
            padding: 60px 30px;
        }
    }

    &-bottom {
        background: #fff;
        height: 150px;
        width: 90%;
        padding: 15px;
        position: fixed;
        bottom: 0px;
        right: -100%;
        -webkit-transition: right 0.5s ease;
        -moz-transition: right 0.5s ease;
        -ms-transition: right 0.5s ease;
        -o-transition: right 0.5s ease;
        transition: right 0.5s ease;

        &.minicart-expanded {
            right: 20px;
        }

        @media (min-width: 375px) {
            width: 325px;

            &.minicart-expanded {
                right: 0px;
            }
        }

        @media (min-width: @screen-width-desktop) {
            width: 525px;
        }

        @media (min-width: @screen-width-xl-desktop) {
            width: 605px;
            padding: 30px;
            height: 190px;
        }
    }

    &-closebtn {
        width: 16px;
        height: 16px;
        position: absolute;
        top: 15px;
        right: 15px;
        border: none;
        padding: 0;
        cursor: pointer;
        border-radius: 0;
        background: none;
    }



    &-productlist {
        padding-top: 10px;
        height: 85%;
        overflow-x: hidden;
        overflow-y: auto;
    }

    &-tothecart-btn {
        width: 100%;
        display: inline-block;
        text-align: center;
    }

    &-orderdetails {
        padding-bottom: 10px;

        div {
            display: flex;
            justify-content: space-between;
            padding-bottom: 3px;

            span {
                font-family: @font-family-sans-serif;
                font-size: 12px;
                font-style: italic;
                line-height: 1.42;
                letter-spacing: -0.34px;
                color: @grey;

                @media (min-width: @screen-width-xl-desktop) {
                    font-size: 14px;
                }
            }

            &.total-price {
                span {
                    font-style: normal;
                    font-weight: bold;
                    color: #000;
                }
            }
        }
    }

    .cart {
        &__quantity-btns {
            right: 100px;
        }

        &__productitem {
            &__container {
                width: 240px;

                @media (min-width: @screen-width-xl-desktop) {
                    width: 260px;
                }
            }
        }
    }
}
