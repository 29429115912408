.modal-container {
    position: fixed;
    z-index: 1010;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(172, 171, 171, 0.4);
    display: none;

    &__is-visible {
        display: block;
    }

    &__transparent {
        background: transparent;
    }
}

.broadcast-message {
    display: none;

    &--visible {
        display: block;
    }
}

.popup-modal {
    height: auto;
    width: calc(100% - 30px);
    border-radius: 4px;
    background-color: #fff;
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    padding: 45px;
    opacity: 0;
    pointer-events: none;
    transition: all 300ms ease-in-out;
    z-index: 0;
    text-align: center;

    @media (min-width: @screen-width-desktop) {
        background-color: @backgroundgrey;
        width: 520px;
    }

    &__is-visible {
        opacity: 1;
        pointer-events: auto;
        z-index: 1011;
    }

    &__close {
        position: absolute;
        font-size: 1.2rem;
        right: 15px;
        top: 15px;
        cursor: pointer;
        background-image: url(/Resources/Graphics/close-icon.svg);
        background-size: 20px;
        background-repeat: no-repeat;
        width: 20px;
        height: 20px;

        span {
            position: absolute;
            left: -10000px;
            top: auto;
            width: 1px;
            height: 1px;
            overflow: hidden;
        }
    }
}

.addtocart-modal-popup {
    background-color: #fff;
    padding: 0;
    height: auto;
    position: fixed;
    top: 136px;
    border-radius: 4px;
    border: 6px solid #e5e5e5;

    @media (min-width: @screen-width-tablet) {
        position: fixed;
        right: 30px;
        top: 66px;
        width: 355px;
        height: auto;
        transform: none;
        left: unset;
    }
    @media (min-width: @screen-width-desktop) {
        right: 55px;
    }
    @media (min-width: @screen-width-ipad) {
        right: 75px;
    }
    @media (min-width: @screen-width-lg-desktop) {
        right: 65px;
    }
    @media (min-width: @screen-width-xl-desktop) {
        right: 100px;
    }
    @media (min-width: @screen-width-xxl-desktop) {
        right: 90px;
    }
    @media (min-width: @screen-width-xxxl-desktop) {
        right: 210px;
    }

    &__inner {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        align-items: center;
        padding: 15px;
    }

    &__innerproductinformation {
        display: inline-flex;
    }

    &__image {
        img {
            width: 48px;
            height: auto;

            @media (min-width: @screen-width-desktop) {
                width: 70px;
            }
        }
    }

    &__information {
        padding-left: 15px;
        margin-bottom: 7px;

        .product-name,
        .product-price {
            .sans-regular;
            color: @black;
            font-size: 11px;
            line-height: 1.45;
            text-align: left;

            @media (min-width: @screen-width-desktop) {
                font-size: 11px;
                line-height: 1.5;
            }
        }

        .product-price {
            padding-top: 3px;
            color : @fail;
        }
    }

    &__checkout-button {
        padding-top: 3px;
    }

    &__heading {
        .sans-regular;
        color: @black;
        font-size: 18px;
        line-height: 1.22;
        padding-bottom: 5px;
        text-align: left;

        @media (min-width: @screen-width-desktop) {
            font-size: 20px;
            line-height: 1.4;
        }
    }
}

.calculatormodal {
    background: #ffffff;
    width: 90%;
    height: auto;

    @media (min-width: @screen-width-tablet) {
        width: 542px;
    }

    .h4 {
        width: 60%;
        margin: 0 auto;
        text-align: center;
    }

    &-inputs {
        padding: 10px 0;
        display: flex;
        justify-content: space-between;
        align-items: center;

        div {
            width: 50%;
            padding: 10px;
            text-align: left;
        }

        label {
            .sans-medium;
            font-size: 13px;
            margin-bottom: -12px;
            display: block;
            text-align: left;
        }
    }

    .calculator-btn {
        text-align: center;
    }

    #roll-result,
    #package-result {
        margin: auto;
    }

    #package-result {
        .sans-medium;
        font-size: 16px;
    }

    a {
        text-decoration: underline;
    }
}

.packagecalculator {
    .calculatormodal-inputs {
        justify-content: center;

        label {
            text-align: left;
        }
    }
}

.ordersamplemodal {
    label {
        display: flex;
        text-align: left;
        padding: 15 5px;

        @media (min-width: @screen-width-desktop) {
            padding: 15px 70px;
        }

        span {
            display: inline-block;
            padding-left: 5px;
        }
    }

    #submitColorSample {
        margin: 15px 15px 0 15px;
    }

    p {
        line-height: 1.2;
    }

    ul {
        list-style: none;
        padding: 0;

        li {
            display: flex;
            justify-content: space-evenly;
            background-color: #fff;
            padding: 15px;
            margin-bottom: 15px;

            .left {
                margin-right: 10px;

                img {
                    width: 100px;
                    height: 100px;
                    object-fit: cover;
                }
            }

            .right {
                display: flex;
                flex-direction: column;
                text-align: initial;

                .name {
                    // TODO ändra till @font-family-sans-serif-semibold efter merge
                    font-family: @font-family-sans-serif;
                    font-size: 16px;
                    font-weight: 500;
                }

                .color {
                    font-size: 12px;
                }

                button {
                    margin: 0;
                    margin-top: 10px;
                    padding: 0 5px;
                    font-size: 11px;
                    background: initial;
                    color: @accent-second;

                    @media (min-width: @screen-width-desktop) {
                        font-size: 13px;
                    }
                }
            }
        }
    }
}

.choose-store-popup {
    width: 320px;
    height: auto;
    padding-bottom:50px;
    border-radius: 4px;
    border: 6px solid #fff;
    background-color: #becfc8;
    font-size: 15px;
    position: fixed;
    z-index: 999999;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    top: 45px;
    text-align: center;
    white-space: initial;

    @media (min-width: @screen-width-desktop) {
        width: 420px;
        height: auto;
        font-size: 17px;
        top: 0px;
    }

    @media (min-width: @screen-width-lg-desktop) {
        right: 81px;
    }

    @media (min-width: @screen-width-xl-desktop) {
        right: 186px;
    }

    div {
        margin: 20px;

        @media (min-width: @screen-width-desktop) {
            margin: 30px;
        }
    }

    a {
        margin-top: 15px;
        position: absolute;
        bottom: 20px;
        left: 50%;
        transform: translateX(-50%);
        padding: 16px 25px;

        @media (min-width: @screen-width-desktop) {
            margin-top: 20px;
        }
    }

    &__close {
        position: absolute;
        font-size: 1.2rem;
        right: 8px;
        top: 8px;
        cursor: pointer;
        background-image: url(/Resources/Graphics/close-icon.svg);
        background-size: 15px;
        background-repeat: no-repeat;
        width: 15px;
        height: 15px;

        span {
            position: absolute;
            left: -10000px;
            top: auto;
            width: 1px;
            height: 1px;
            overflow: hidden;
        }
    }
}