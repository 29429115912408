.articlepage {
    &.textpage {
        @media (max-width:@screen-width-tablet) {
            padding-top: 25px;
        }
    }

    &__image {
        @media (max-width: 767px) {
            margin: -15px;
        }
    }

    &__content {
        margin : 0 auto;
        padding: 30px 0 0 0;

        @media (min-width:@screen-width-desktop) {
            position  : relative;
            top       : -170px;
            width     : 625px;
            background: rgba(246, 246, 246, 0.9);
            padding   : 40px;
        }

        @media (min-width:@screen-width-lg-desktop) {
            width: 760px;
        }

        @media (min-width:@screen-width-xl-desktop) {
            width: 925px;
        }

        p a {
            text-decoration: underline;
        }
    }

    .textpage__heading,
    .preamble {

        @media (min-width: @screen-width-lg-desktop ) {
            max-width: 660px;
        }

        @media (min-width: @screen-width-xl-desktop ) {
            max-width: 823px;
        }
    }

    &__blockarea {
        @media (min-width:@screen-width-desktop) {
            margin-top: -165px;
        }
    }

    &.no-article-image {
        .articlepage__content {
            @media (min-width:@screen-width-desktop) {
                top: 0;
            }
        }

        .articlepage__blockarea {
            @media (min-width:@screen-width-desktop) {
                margin-top: 0px;
            }
        }
    }
}