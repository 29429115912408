.store-picker-warning {
    display         : none;
    width           : 90%;
    height          : auto;
    border-radius   : 4px;
    box-shadow      : 0 0 10px 0 rgba(87, 86, 86, 0.2);
    background-color: #ffffff;
    padding         : 20px;
    position        : fixed;
    top             : 50%;
    left            : 50%;
    transform       : translate(-50%, -50%);
    text-align      : center;
    z-index         : 1020;
    min-height      : 283px;


    @media (min-width: @screen-width-desktop) {
        width : 375px;
        height: 230px;
        top   : 20%;
    }

    @media (min-width: @screen-width-xl-desktop) {
        width: 375px;
    }

    h4{
        margin-top:22%;
    }

    .search-button {
        position: absolute;
        bottom  : 30px;
        left    : 10px;

        @media (min-width: @screen-width-desktop) {
            left: 30px;

        }
    }

    .btn-green-arrow {
        position: absolute;
        bottom  : 30px;
        right   : 10px;

        @media (min-width: @screen-width-desktop) {
            right: 30px;

        }
    }
}

.store-picker {
    display: none;
    width: 100%;
    height: 100%;
    background-color: @backgroundgrey;
    z-index: 999999;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    padding: 30px 30px 0 30px;
    border-radius: 5px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;

    @media (min-width: @screen-width-desktop) {
        width: 435px;
        height: 550px;
        top: 10%;
        padding-left: 30px;
        padding-right: 30px;
    }

    @media (min-width: @screen-width-xl-desktop) {
        top: 17%;
        width: 453px;
    }

    p {
        font-weight: bold;
        text-align: center;
    }

    &__close {
        position: absolute;
        top: 15px;
        right: 15px;
    }

    &__heading {
        text-align: center;
        font-family: @font-family-sans-serif-medium;
        font-weight: 500;
        font-size: 20px;
        line-height: 28px;
    }
    &__text{
        display:block;
        margin-bottom:10px;
        text-align: center;
    }

    .search-input-wrapper,
    .search-input-wrapper .search-input {
        width: 100%;
        position: relative;
        height: 50px;
    }

    .search-input-wrapper {
        height: 110px;

        .search-input::placeholder {
            opacity: 1;
            font-size: 16px;
        }

        .search-input-button {
            margin-bottom: 7px;
            font-size: 16px;
            width: 100%;
            height: 50px;
            font-style: normal;
            color: @dark-third;
            text-align: left;
            padding-left: 15px;
            font-weight: normal;
            background-color: fade(@light, 30%);
            border: 1px solid @light-third;

            &.selectiondone {
                background-color: white;
                color: @accent;
            }
        }

        .arrow {
            border: solid black;
            border-width: 0 2px 2px 0;
            display: inline-block;
            padding: 4px;
            position: absolute;
            top: 24px;
            right: 20px;
            cursor: pointer;
        }

        .arrow-down {
            transform: rotate(45deg);
            -webkit-transform: rotate(45deg);
            top: 20px
        }

        .arrow-up {
            transform: rotate(-135deg);
            -webkit-transform: rotate(-135deg);
        }

        .search-input-dropdown {
            display: block;
            position: absolute;
            height: 340px;

            @media (min-width: @screen-width-desktop) {
                width: 375px;
            }

            li {
                .sans-medium;
                min-height: 50px;
                margin: 0;
                display: flex;
                align-items: center;
                cursor: pointer;
            }

            li:hover {
                background-color: #D9ECD9;
            }
        }

        .dropdown-container {
            background-color: white;
            width: 100%;
            height: 375px;
            position: absolute;
            padding-top: 10px;
            z-index: 11;
            box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
            display: none;

            @media (min-width: @screen-width-desktop) {
                width: 393px;
            }
        }

        .dropdown.closed .search-input-dropdown li {
            display: none;
        }

        .search-button {
            top: 63px;
        }
    }

    &__results {
        overflow-y: auto;
        height: 80%;
        padding-top: 15px;

        @media (min-width: @screen-width-desktop) {
            height: 215px;
            width: 405px;
            margin-top: 15px;
            padding-top: 0px;
        }
    }

    ul {
        list-style-type: none;
        padding: 0 10px 0 0;
        margin: 0;
    }

    li {
        background-color: white;
        min-height: 68px;
        padding: 8px 30px 8px 15px;
        margin-bottom: 6px;

        a {
            div:first-of-type {
                .sans-medium;
                padding-top: 5px;
                padding-bottom: 5px;
            }
        }
    }

    &__noresult {
        position: absolute;
        top: 300px;
        left: 50%;
        transform: translateX(-50%);
        width: 100%;
        text-align: center;

        @media (min-width: @screen-width-desktop) {
            top: 400px;
        }
    }
}

.store-picker-list__item {
    position: relative;
    /*#pickup-marker {
        width: 11px;
        position: relative;
        top: 1px;
    }*/

    .arrow-right {
        position: absolute;
        top: 15px;
        right: 15px;
    }

    &__address {
        font-size: 14px;
        letter-spacing: -0.4px;
        display: flex;
        flex-wrap: wrap;
        
        .locationheader{
            display:block;
            float:left;
            width:100%;
        }
        
        .pickup-point-marker {
            width: 9px;
            position: relative;
            top: 1px;
        }

        .pickup-point {
            position: relative;
            padding: 0px 12px 5px 0px;
            font-size: 13px;
            /*&:before {
                content: "";
                width: 4px;
                height: 4px;
                display: block;
                border-radius: 50%;
                background: @orange;
                position: absolute;
                left: 0;
                top: 6px;
            }*/
        }
    }
}

.store-picker-overlay {
    display         : none;
    height          : 100%;
    width           : 100%;
    background-color: rgba(172, 171, 171, 0.5);
    position        : fixed;
    top             : 0;
    left            : 0;
    z-index         : 0;
    z-index         : 1000;
}

.store-picker__results.black-scrollbar::-webkit-scrollbar {
    background-color: initial;
}

.store-picker__results.black-scrollbar::-webkit-scrollbar-track {
    margin-bottom: 25px;
}