@import "../Main.less";
@import "../Variables.less";

.autocomplete {
    background: #ED8296;
    /*the container must be positioned relative:*/
    position: relative;
    display: inline-block;

    &-items {
        position: absolute;
        border: 1px solid #d4d4d4;
        border-bottom: none;
        border-top: none;
        z-index: 99;
        /*position the autocomplete items to be the same width as the container:*/
        top: 100%;
        left: 0;
        right: 0;
    }

    &-items div {
        padding: 10px;
        cursor: pointer;
        background-color: #fff;
        border-bottom: 1px solid #d4d4d4;
    }

    &-items div:hover {
        /*when hovering an item:*/
        background-color: #e9e9e9;
    }

    &-active {
        /*when navigating through the items using the arrow keys:*/
        background-color: DodgerBlue !important;
        color: #ffffff;
    }
}

.product {
    &-content {
        padding-bottom: 40px;
        padding-top: 40px;

        @media (min-width: @screen-width-desktop) {
            padding-bottom: 70px;
        }

        .h4 {
            margin: 0 auto 10px auto;
        }
    }

    &-movieiframe {
        display: none;
        position: fixed;
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        z-index: 1001;
        width: 100%;
        height: 215px;

        @media (min-width: @screen-width-lg-desktop) {
            width: 700px;
            height: 423px;
        }
    }

    &-movieoverlay {
        display: none;
        height: 100%;
        width: 100%;
        background-color: rgba(171, 171, 171, .5);
        position: fixed;
        top: 0;
        left: 0;
        z-index: 1000;
    }

    &-images {
        #productimage-thumbnails {
            display: none;

            .thumbnail-color {
                transition: opacity .2s ease-in;
                margin-right: 3px;
            }

            picture {
                margin-right: 3px;
                opacity: 0.5;
                transition: opacity .2s ease-in;

                &:first-child {
                    margin-right: 0;
                }

                &.tns-nav-active {
                    opacity: 1;
                }

                &:hover,
                &:focus,
                &:active {
                    outline: none;
                    opacity: 1;
                }
            }

            .thumbnail {
                height: 58px;
                width: 58px;
                cursor: pointer;
            }
            /*.Oval {
                width: 17px;
                height: 17px;
                padding: 9px 10px 8px 11px;
                opacity: 0.5;
                background-color: #ffffff;
                position: absolute;
                border-radius: 9px;
                top: 50%;
                left: 50%;
                -webkit-transform: translate(-50%, -50%);
                transform: translate(-50%, -50%);
            }

            .product-thumbnails__playbutton {
                width: 8px;
                height: 10px;
                object-fit: contain;
                position: absolute;
                top: 50%;
                left: 50%;
                -webkit-transform: translate(-50%, -50%);
                transform: translate(-50%, -50%);
            }*/
        }

        &__carousel {
            .imgContainer {
                cursor: pointer;
            }

            .Oval {
                width: 87px;
                height: 87px;
                padding: 29px 30px 28px 31px;
                opacity: 0.5;
                background-color: #ffffff;
                position: absolute;
                border-radius: 44px;
                top: 50%;
                left: 50%;
                -webkit-transform: translate(-50%, -50%);
                transform: translate(-50%, -50%);
            }

            &__playbutton {
                width: 26px;
                height: 30px;
                object-fit: contain;
                position: absolute;
                top: 50%;
                left: 50%;
                -webkit-transform: translate(-50%, -50%);
                transform: translate(-50%, -50%);
            }
        }

        .swiper {
            width: 100%;
            height: 288px;
        }

        .swiper-slide {
            text-align: center;
            font-size: 18px;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 80%;

            img {
                display: block;
                width: 100%;
                height: 100%;
                object-fit: cover;
                background: #fff;
            }
        }

        .swiper-pagination {
            text-align: right;

            &-fraction {
                left: initial;
                right: 9px;
                background-color: white;
                width: 40px;
                padding: 5px;
            }
        }

        @media (max-width: @screen-width-tablet) {
            height: 288px;
        }
    }

    &-properties {
        position: relative;
        padding-top: 0px;

        @media (min-width: @screen-width-desktop) {
            padding-left: 60px;
        }

        &__name-and-price {
            @media (min-width: @screen-width-lg-desktop) {
                display: flex;
                justify-content: space-between;
            }
        }

        &__not-available {
            @media (min-width: @screen-width-lg-desktop) {
                flex-direction: column;
            }
        }

        &__product-name {
            flex-shrink: 5;

            @media (max-width: @screen-width-tablet) {
                padding: 0 90px 0 0;
            }

            h1 {
                font-size: 30px;
                margin: 0 0 10px 0;

                @media (min-width: @screen-width-tablet) {
                    font-size: 34px;
                }
            }
        }

        &__product-brand {
            .sans-medium;
            font-size: 16px;
            line-height: 1.25;
            color: @accent-second;
            margin-bottom: 0px;
        }

        &__wishlist-button {
            display: none;

            @media (min-width: @screen-width-tablet) {
                display: initial;
                background: none;
                position: absolute;
                top: 5px;
                right: 15px;
                margin: 0;
                padding: 0;
                background-color: white;
                border-radius: 50%;
                width: 40px;
                height: 40px;

                svg {
                    position: relative;
                    right: 0px;
                    left: 1px;
                    top: 4px;
                }
            }

            &:hover {
                background: none;
            }
        }

        &__price {
            .sans-medium;
            font-size: 18px;
            line-height: 1.22;
            margin-bottom: 10px;
            color: @fail;

            .m2price {
                color: #BE1515;
            }

            @media (min-width: @screen-width-tablet) {
                font-size: 20px;
                line-height: 1.4;
            }

            @media (min-width: @screen-width-lg-desktop) {
                padding-right: 5px;
                padding-top: 10px;
                flex-basis: 30%;
                margin-bottom: 10px;
            }

            .not-available {
                padding: 20px 23px;
                background: #fff;
                display: flex;
                justify-content: center;
                align-items: center;
                flex-wrap: wrap;

                @media (min-width: @screen-width-desktop) {
                    justify-content: space-between;
                }

                > div {
                    text-align: center;
                    font-size: 12px;
                    color: @grey;
                    font-style: italic;
                    font-family: @font-family-sans-serif;
                    font-weight: normal;
                    letter-spacing: -0.34px;

                    @media (min-width: @screen-width-lg-desktop) {
                        width: 40%;
                        text-align: left;
                    }

                    @media (min-width: @screen-width-xl-desktop) {
                        width: 50%;
                    }

                    @media (min-width: @screen-width-xxl-desktop) {
                        width: 60%;
                    }
                }
            }

            .available {
                @media (min-width: @screen-width-lg-desktop) {
                }
            }

            a > span {
                display: inline-block;

                @media (max-width: 1023px) {
                    margin-top: 15px;
                }
            }

            &__ordinary {
                font-size: 16px;
                line-height: 1.90;
                font-style: italic;
                font-weight: normal;
                margin-top: 0px;
                margin-bottom: 0px;
                color: @dark-third;
                text-decoration: line-through;

                @media (max-width: 576px) {
                    font-size: 14px;
                    line-height: 1.70;
                }

                .space-between-price {
                    @media (min-width: @screen-width-lg-desktop) {
                        margin-left: 10px;
                    }
                }
            }

            &__ordinarypackage {
                font-size: 16px;
                line-height: 1.90;
                font-weight: normal;
                margin-top: 7px;
                margin-bottom: 10px;
                color: #000000;

                @media (max-width: 576px) {
                    font-size: 14px;
                    line-height: 1.70;
                }


                .space-between-price {
                    @media (min-width: @screen-width-lg-desktop) {
                        margin-left: 10px;
                    }
                }
            }

            &__discounted {
                color: #BE1515;
                float: left;
                margin-right: 15px;
            }

            &__package {
                margin-bottom: 10px;
            }
        }

        &__variant-collection {
            padding-top: 0px;
            padding-bottom: 20px;

            .h4 {
                margin: 0;
            }

            .thumbnail {
                border: 3px solid #d6d6d6;
            }

            &.hide {
                display: none;
            }
        }

        &__thumbnails {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            margin: 0 -5px;

            .thumbnail {
                border-radius: 50%;
                height: 58px;
                width: 58px;
                margin: 5px;
                cursor: pointer;

                &.selected {
                    border: 1px solid #000;
                    -webkit-box-shadow: inset 0px 0px 0px 3px rgba(246, 246, 246, 1);
                    -moz-box-shadow: inset 0px 0px 0px 3px rgba(246, 246, 246, 1);
                    box-shadow: inset 0px 0px 0px 3px rgba(246, 246, 246, 1);
                }

                &.customcolor {
                    &.selected {
                        box-shadow: none;
                        border: 1px solid #000;
                        padding-top: 3px;
                    }
                }
            }

            a {
                text-align: center
            }
        }

        &__selected-product {
            .sans-medium;
            padding-bottom: 4px;

            @media (min-width: @screen-width-tablet) {
                line-height: 1.5;
            }

            span {
                color: @dark;
                font-weight: normal;

                @media (min-width: @screen-width-tablet) {
                    line-height: 1.75;
                }
            }
        }

        .scroll-count-button {
            background: none;
            padding: 0;
            text-decoration: underline;
            color: @black;
            font-size: 14px;
            margin-bottom: 10px;
            margin-right: 10px;
        }

        &__coloramount {
            padding-top: 0px;
            padding-bottom: 20px;

            label {
                .sans-medium;
                display: block;
            }

            #product-properties-label {
                margin: 0;
                padding-bottom: 4px;
                font-size: 14px;

                @media (min-width: @screen-width-tablet) {
                    font-size: 16px;
                }
            }
        }

        .add-to-cart {
            display: flex;
            flex-flow: row wrap;
            align-items: center;
            margin: 0 0px 20px 0;
            row-gap: 15px;

            @media (max-width: @screen-width-tablet) {
                justify-content: center;
            }

            &__change-quantity {
                display: flex;
            }

            &__button {
                margin: 0 15px;
                min-width: 211px;
                background: #C14747;
                color: #fbfbfb;

                &:disabled {
                    background: @light-second;
                    color: @dark-third;
                }

                @media (max-width: @screen-width-lg-desktop) {
                    padding: 14px 25px;
                    min-width: 161px;
                }
            }

            form {
                margin: 0 15px;
            }
        }

        .order-sample-btn {
            margin-top: 20px;
            padding: 14px 25px;

            @media (min-width: 521px) {
                margin-top: 0;
            }

            @media (min-width: 768px) {
                margin-top: 20px;
            }

            @media (min-width: 992px) {
                margin-top: 0px;
            }

            @media (min-width: 1025px) {
                margin-top: 20px;
            }

            @media (min-width: 1200px) {
                margin-top: 0px;
            }

            @media (max-width: @screen-width-lg-desktop) {
                padding: 12px 20px;
            }
        }

        #submitColorSample {
            /*margin-top: 15px;*/
            color: @accent-second;
            background-color: initial;


            @media (min-width: @screen-width-tablet) {
                margin-left: 0px;
            }

            @media (min-width: @screen-width-xxl-desktop) {
                margin-top: 0px;
            }

            &:hover {
                background: @accent-second;
                color: #fff;
            }

            &:disabled {
                display: none;
            }
        }

        .selling-points {
            list-style: none;
            padding: 0;
            display: flex;
            flex-wrap: wrap;

            @media (max-width: @screen-width-tablet) {
                justify-content: center;
            }

            li {
                font-size: 11px;
                font-weight: normal;
                line-height: 1.45;
                color: black;
                margin-right: 20px;

                @media (min-width: @screen-width-tablet) {
                    font-size: 12px;
                    line-height: 1.5;
                }

                &::before {
                    content: "";
                    position: relative;
                    top: -1px;
                    width: 5px;
                    height: 5px;
                    border-radius: 50%;
                    display: inline-block;
                    margin-right: 5px;
                    background-color: @peagreen;

                    @media (min-width: @screen-width-lg-desktop) {
                        top: -3px;
                    }
                }
            }
        }

        .store-choice-notchosen {
            display: flex;
            justify-content: space-between;
            // .store-choice-heading {
            //     padding-bottom: 10px;
            //     min-height: 55px;
            //     @media (min-width: @screen-width-tablet) {
            //         min-height: auto;
            //     }
            // }
            &more-links {
                display: none;
            }
            // .btn {
            //     display: inline-block;
            //     padding: 14px 50px;
            // }
        }
    }

    &-information {
        line-height: 1.75;
        margin-top: 20px;

        @media (min-width: @screen-width-tablet) and (max-width: @screen-width-desktop ) {
            padding-bottom: 40px;
        }

        @media (max-width: @screen-width-desktop) {
            margin-top: 50px;
        }

        @media (max-width: @screen-width-tablet) {
            padding-right: 15px;
            padding-left: 15px;
            margin-top: 70px;

            .accordion-item {
                border-top: 1px solid @bordergrey;
            }
        }
    }

    &-spec {
        @media (min-width: @screen-width-lg-desktop) {
            padding-left: 60px;
        }

        ul {
            list-style: none;
            margin: 5px 0 0 0;
            padding: 0 0 0 13px;

            li {
                margin: 0 0 15px 0;

                &::before {
                    content: "";
                    position: relative;
                    top: -1px;
                    width: 3px;
                    height: 3px;
                    border-radius: 50%;
                    display: inline-block;
                    margin-right: 20px;
                    background-color: @black;

                    @media (min-width: @screen-width-lg-desktop) {
                        top: -4px;
                    }
                }
            }
        }
    }

    &-colorcombinations {
        position: relative;
        margin-top: 40px;

        @media (min-width: @screen-width-tablet) {
            margin-top: 0px;
            margin-bottom: 40px;
        }


        .row {
            padding: 0 10px;

            @media (min-width: @screen-width-lg-desktop) {
                padding: 0;
            }
        }


        .combination__heading {
            margin: 0;

            @media (max-width: @screen-width-tablet) {
                font-size: 14px;
                line-height: 1.57;
            }
        }

        .combinations {
            position: relative;
        }

        .combination-buttons {
            padding-bottom: 15px;
            padding-top: 5px;

            @media (min-width: @screen-width-desktop) {
                position: absolute;
                right: 0;
                top: -13px;
                padding-bottom: 0;
            }

            @media (min-width: @screen-width-lg-desktop) {
                top: -30px;
            }

            button {
                border-radius: 58px;
                margin-right: 5px;
                background-color: @accent;
                border: 1px solid @accent;
                height: 32px;
                font-size: 11px;
                line-height: 1.45;
            }
        }

        .color-combination-item {
            padding: 0 5px;

            @media (min-width: @screen-width-lg-desktop) {
                padding: 10px 15px;
            }

            .combination-color {
                height: 74px;

                @media (min-width: @screen-width-lg-desktop) {
                    height: 90px;
                }

                @media (min-width: @screen-width-xl-desktop) {
                    height: 126px;
                }
            }

            .combination-name {
                background: #fff;
                height: 42px;
                font-style: italic;
                line-height: 1.43;
                letter-spacing: -0.4px;
                color: @grey;
                font-family: @font-family-sans-serif;
                font-weight: normal;
                font-size: 10px;
                text-align: center;
                display: flex;
                justify-content: center;
                align-items: center;

                @media (min-width: @screen-width-xl-desktop) {
                    font-size: 14px;
                }
            }
        }
    }

    .accordion-content {
        margin: 20px;

        @media (min-width: @screen-width-tablet) {
            font-size: 16px;
            line-height: 1.75;
        }
    }

    .accordion-content ul li a,
    .accordion-content p a {
        text-decoration: underline;
    }

    .accordion-heading {
        margin: 0;
        margin-left: 20px;
        margin-right: 20px;
    }

    .accordion-item {
        padding: 20px 0;
        background-color: white;
        border-radius: 4px;
        margin-bottom: 10px;
        border: initial;
    }
}

.product-listing {

    &__collection {
        @media (max-width: @screen-width-tablet) {
            margin-right: -5px;
            margin-left: -5px;
        }

        .row {
            flex-wrap: wrap;

            @media (max-width: @screen-width-tablet) {
                margin-right: 5px;
                margin-left: -5px;
            }
        }

        .product-list-item {
            background-color: #ffffff;
            position: relative;
            text-align: left;
            width: calc(50% - 10px);
            margin: 5px;

            &__price {
                color: @fail;
            }


            @media (min-width: @screen-width-tablet) {
                &:hover {
                    img {
                        transition: opacity .2s ease-out;
                        opacity: 70%;
                    }
                }

                background-color: transparent;
                margin: 15px;
                width: calc(25% - 30px);
            }

            @media (min-width: @screen-width-lg-desktop) {
                width: calc(25% - 30px);
            }

            .circle {
                padding: 15px;
                cursor: pointer;
                position: absolute;
                z-index: 1;
                background: white;
                border-radius: 20px;
                right: 10px;
                top: 10px;

                @media (min-width: @screen-width-tablet) {
                    padding: 20px;
                    right: 10px;
                    top: 10px;
                }
            }

            .label {
                display: none;

                @media (min-width: @screen-width-tablet) {
                    position: absolute;
                    border-radius: 2px;
                    height: 26px;
                    display: inline-flex;
                    top: 20px;
                    left: 15px;
                    z-index: 1;
                }

                span {
                    .sans-medium;
                    margin: auto;
                    font-size: 12px;
                    color: #ffffff;
                    padding-left: 10px;
                    padding-right: 10px;
                }
            }

            a {
                color: black;
                text-decoration: none;
            }

            &__heart {
                cursor: pointer;
                position: absolute;
                top: 5px;
                right: 7px;
                width: 17px;

                @media (min-width: @screen-width-tablet) {
                    top: 11px;
                    right: 7px;
                    width: 24px;

                    &:hover {
                        path {
                            fill: @accent;
                            stroke: @accent;
                        }
                    }
                }
            }

            &__content {
                padding: 0px;
            }

            &__brand {
                .sans-medium;
                font-size: 12px;
                line-height: 18px;

                @media (min-width: @screen-width-tablet) {
                    font-size: 16px;
                    line-height: 24px;
                }
            }

            &__name {
                .sans-medium;
                font-size: 16px;
                margin: 0;
                line-height: 24px;

                @media (min-width: @screen-width-tablet) {
                    font-size: 20px;
                    line-height: 28px;
                }
            }

            &__price {
                font-size: 12px;
                /*color: @text-color;*/
                color: @fail;
                .sans-medium;
                line-height: 18px;

                @media (min-width: @screen-width-tablet) {
                    font-size: 16px;
                    line-height: 24px;
                }

                &--discounted {
                    color: @fail;
                    margin-right: 2px;
                }

                &--recommended {
                    color: @gray-light;
                    font-weight: normal;
                    text-decoration: line-through;
                    margin-right: 5px;
                }
            }

            &__listprice {
                font-size: 0.8em;
            }

            &__image {
                position: relative;
                overflow: hidden;

                @media (min-width: @screen-width-tablet) {
                    padding-bottom: 100%;
                }

                img {
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    left: 0;

                    @media (max-width: @screen-width-tablet) {
                        padding-left: 0;
                        padding-right: 0;
                    }
                }
            }

            &__buybtn {
                display: none;
                padding: 0;
                background: none;

                svg {
                    width: 27px;
                }
            }
        }
    }

    .variants {
        display: flex;
        justify-content: left;
        padding: 5px 0;

        .variant-custom {
            width: 14px;
            height: 14px;
            margin: 0 6px 0 0px;

            @media (max-width: @screen-width-tablet) {
                /*margin: -1px 3px;*/
            }

            svg {
                top: 0px;
                position: relative;
                width: 14px;
                height: 14px;
            }

            @media (min-width: @screen-width-tablet) {
                width: 29px;
                height: 29px;

                svg {
                    width: 29px;
                    height: 29px;
                }
            }
        }

        .variant-color {
            width: 14px;
            height: 14px;
            border-radius: 50%;
            margin: 0 6px 0 0px;
            border: none;

            @media (min-width: @screen-width-tablet) {
                width: 29px;
                height: 29px;
            }
        }

        span {
            margin: 7px 0 0px 7px;
            font-size: 12px;
            font-style: italic;
            line-height: 1.58;
            letter-spacing: -0.34px;
            color: @grey;

            @media (max-width: @screen-width-tablet) {
                line-height: 0.2;
            }
        }
    }

    @media (min-width: @screen-width-tablet) {
        &.related-products {
            h5 {
                margin: 0;
            }

            .product-list-item {

                &__brand,
                &__name {
                    font-size: 14px;
                    line-height: 1.29;
                }

                &__price {
                    font-size: 11px;
                    line-height: 1.45;
                }
            }
        }
    }

    &.related-products {
        .product-list-item {
            width: calc(33% - 30px);
        }
    }
}

.productdetailspage {
    .product-listing {
        @media (max-width: @screen-width-tablet) {
            padding: 60px 0 40px;
        }
    }

    .product-images {
        .label {
            z-index: 1;
            position: absolute;
            border-radius: 2px;
            height: 16px;
            display: flex;
            margin-left: 15px;
            margin-top: 15px;

            @media (min-width: @screen-width-tablet) {
                height: 26px;
            }

            span {
                .sans-medium;
                margin: auto;
                font-size: 9px;
                line-height: 1.33;
                color: #ffffff;
                padding-left: 5px;
                padding-right: 5px;

                @media (min-width: @screen-width-tablet) {
                    font-size: 12px;
                    line-height: 1.5;
                    padding-left: 10px;
                    padding-right: 10px;
                }
            }
        }

        .circle {
            padding: 15px;
            cursor: pointer;
            position: absolute;
            z-index: 1;
            background: white;
            border-radius: 20px;
            right: 30px;
            top: 10px;

            @media (min-width: @screen-width-tablet) {
                display: none;
            }
        }

        &__heart {
            cursor: pointer;
            position: absolute;
            top: 5px;
            right: 6px;
            width: 17px;
        }
    }

    .products-in-same-category {
        padding-top: 40px;
    }
}

.productcategoryblock {
    padding: 40px 10px 60px 10px;

    @media (min-width: @screen-width-xxl-desktop) {
        padding: 40px 0 60px 0;
    }

    .categorypage__products {
        position: relative;
    }

    &__btn {
        text-align: center;
        margin-top: 60px;
    }

    &__dropdown {
        position: absolute;
        top: 0;
        right: 0;
    }
}

.wallpapercalculator {
    .sans-medium;
    background-color: @highlight;
    padding: 8px;
    display: flex;
    align-items: center;
    font-size: 11px;
    line-height: 1.45;
    width: fit-content;
    margin-bottom: 15px;
    border-radius: 4px;


    @media (min-width: @screen-width-tablet) {
        font-size: 12px;
        line-height: 1.5;
    }

    svg {
        padding-right: 5px;
    }

    .scroll-count-button {
        margin: 0;
        padding-left: 2px;
    }

    .scroll-count-button {
        .sans-regular;
        font-size: 11px;
        line-height: 1.45;
        height: 25px;

        @media (min-width: @screen-width-tablet) {
            font-size: 12px;
            line-height: 1.5;
        }
    }
}

.storepicker-container {
    background-color: white;
    padding: 20px;
    border: initial;
    border-radius: 4px;
    display: flex;
    font-size: 16px;
    line-height: 1.50;
    row-gap: 15px;
    width: fit-content;
    
    &__text-container {
        display: flex;
            justify-content: center;
            align-items: center;
        margin-right: 20px;
    }

    div {
        max-width: 400px;
    }

    span {
        font-weight: normal;
    }

    button {
        font-size: 12px;
        width: 200px;
    }

    @media(max-width:1200px) {
        flex-wrap: wrap;

        .btn-container {
            display: flex;
            justify-content: center;
            align-items: center;
        }
        
        div {
            max-width: 100%;
            width: 100%;
        }

        &__text-container {
            margin-right: 0px;
        }
        // .btn-container {
        //     display: flex;
        //     justify-content: center;
        // }
    }

    @media (max-width: 768px) {
        width: 100%;
        .storepicker-container {
            background-color: inherit;


        }


    }
}
