.questionnairepage {

    &.block {
        background-color: white;

        @media (min-width: @screen-width-desktop) {
            min-height: 650px;
        }
    }

    .top {
        background: @darkteal;
        padding: 10px 0 8px 0;
        text-align: center;
        position: relative;

        .modal-top-logo {
            @media (max-width: @screen-width-desktop) {
                height: 28px;
            }
        }

        .goback-btn {
            position: absolute;
            top: 10px;
            left: 20px;
            font-size: 12px;
            font-weight: 500;
            letter-spacing: -0.4px;
            color: #ffffff;

            @media (min-width: @screen-width-desktop) {
                font-size: 14px;
                top: 17px;
            }

            svg {
                margin-right: 10px;
                top: 6px;
                position: relative;

                @media (min-width: @screen-width-desktop) {
                    top: 4px;
                }
            }
        }
    }

    .questionnarie-name {
        background: #fff;
        padding: 16px 0;
        text-align: center;
        border: solid 1px #e8e8e8;

        h2 {
            font-family: @font-family-sans-serif;
            font-size: 12px;
            font-weight: bold;
            line-height: 1.43;
            letter-spacing: -0.4px;
            margin: 0;

            @media (min-width: @screen-width-desktop) {
                font-size: 14px;
            }
        }
    }


    &__entry {
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        background-color: @backgroundgrey;
        padding: 60px 0;
        text-align: center;
        height: 80vh;

        @media (min-width: @screen-width-desktop) {
            padding: 120px 0;
            height: 50vh;
        }

        @media (min-width: @screen-width-lg-desktop) {
            height: 80vh;
        }

        &.block {
            height: auto;

            @media (min-width: @screen-width-desktop) {
                padding: 120px 0;
                height: auto;
            }

            @media (min-width: @screen-width-lg-desktop) {
                height: auto;
            }
        }


        h1 {
            color: #fff;
            text-align: center;
            padding: 0 30px;
            font-size: 34px;
            line-height: 1.03;

            @media (min-width: @screen-width-lg-desktop) {
                font-size: 60px;
                width: 610px;
                margin: 0 auto 15px auto;
            }
        }

        p {
            line-height: 1.78;
            color: #fff;
            font-size: 18px;

            @media (min-width: @screen-width-lg-desktop) {
                font-size: 22px;
                width: 610px;
                margin: 0 auto !important;
            }
        }

        .btn {
            margin-top: 15px;

            @media (min-width: @screen-width-lg-desktop) {
                margin-top: 50px;
            }
        }
    }

    &__modal {
        position: fixed;
        top: 0;
        left: 0;
        background: transparent;
        z-index: -1;
        height: 100vh;
        width: 100%;
        opacity: 0;
        transition: all .4s ease-in;

        &.modal-open {
            background: #fff;
            z-index: 100;
            opacity: 1;
        }

        .modal-top {
            background: @darkteal;
            padding: 10px 0 8px 0;
            text-align: center;
            position: relative;

            .modal-top-logo {
                @media (max-width: @screen-width-desktop) {
                    height: 28px;
                }
            }

            .goback-btn {
                position: absolute;
                top: 10px;
                left: 20px;
                font-size: 12px;
                font-weight: 500;
                letter-spacing: -0.4px;
                color: #ffffff;

                @media (min-width: @screen-width-desktop) {
                    font-size: 14px;
                    top: 17px;
                }

                svg {
                    margin-right: 10px;
                    top: 6px;
                    position: relative;

                    @media (min-width: @screen-width-desktop) {
                        top: 4px;
                    }
                }
            }

            .close-btn {
                background: none;
                border-radius: 0;
                position: absolute;
                top: 13px;
                right: 20px;
                font-size: 12px;
                font-weight: 500;
                letter-spacing: -0.4px;
                color: #ffffff;
                padding: 0;

                @media (min-width: @screen-width-desktop) {
                    font-size: 14px;
                    top: 21px;
                }

                svg {
                    position: relative;
                    margin-left: 4px;
                    top: 4px;

                    @media (min-width: @screen-width-desktop) {
                        margin-left: 10px;
                        top: 3px;
                    }
                }
            }
        }

        .modal-name {
            background: #fff;
            padding: 16px 0;
            text-align: center;
            border: solid 1px #e8e8e8;

            h2 {
                font-family: @font-family-sans-serif;
                font-size: 12px;
                font-weight: bold;
                line-height: 1.43;
                letter-spacing: -0.4px;
                margin: 0;

                @media (min-width: @screen-width-desktop) {
                    font-size: 14px;
                }
            }
        }

        .modal-content {
            background: @backgroundgrey;
            height: 100%;
        }

        .questions-container {
            position: relative;
            padding-top: 15px;
            overflow-y: initial !important;

            @media (min-width: @screen-width-desktop) {
                padding-top: 80px;
            }
        }
    }

    .question__heading {
        font-size: 34px;
        line-height: 1.18;
        letter-spacing: -0.97px;
        font-family: @font-family-serif;
        font-weight: bold;
        margin: 15px 0 10px 15px;
        text-align: center;

        @media (min-width: @screen-width-tablet) {
            font-size: @font-size-h1;
            letter-spacing: -1.03px;
        }

        @media (min-width: @screen-width-desktop) {
            font-size: 50px;
            margin: 15px auto 20px auto;
            max-width: 550px;
            letter-spacing: -1.43px;
        }
    }

    .replies {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;

        @media(max-width: @screen-width-tablet) {
            align-items: center;
        }

        @media (min-width: @screen-width-desktop) {
            padding-top: 20px;
            max-width: 610px;
            margin: 0 auto;


            &.has-image {
                max-width: 100%;

                .reply {
                    @media (min-width: @screen-width-tablet) {
                        width: 295px;
                    }
                }
            }
        }

        .reply {
            margin: 0px 5px 10px 5px;
            width: calc(50% - 10px);
            width: 100%;

            @media (min-width: @screen-width-tablet) {
                margin: 10px;
                width: calc(50% - 20px);
            }

            p {
                text-align: center;
                font-size: 12px;
                font-weight: bold;
                line-height: 1.3;
                border-radius: 0;
                transition: background .2s ease-in;
                cursor: pointer;
                border: 1px solid #e8e8e8;
                margin: 0;
                padding: 16px 10px;

                @media (min-width: @screen-width-desktop) {
                    font-size: 16px;
                }

                &:hover {
                    background: @lightgreen;
                }
            }

            &.has-image {
                width: calc(50% - 10px);
                margin: 5px;
                border: 1px solid #e8e8e8;
                background: #fff;
                overflow: hidden;

                @media (min-width: @screen-width-desktop) {
                    width: calc(25% - 18px);
                    margin: 9px;
                }

                @media (min-width: @screen-width-lg-desktop) {
                    width: 182px;
                    margin: 15px;
                }

                @media(max-width: 370px) {
                    height: 195px;
                }

                img {
                    background-color: #fff;
                    border: 0;

                    @media (min-width: @screen-width-tablet) {
                        width: 100%;
                        height: auto;
                    }
                }

                p {
                    padding-top: 13px;
                    border: none;

                    &:hover {
                        background: none;
                    }
                }
            }
        }
    }

    .moreinformation {
        display: flex;
        padding-top: 65px;
        justify-content: center;

        &__text {
            max-width: 200px;
            padding-left: 15px;
            margin-top: 5px;

            @media (min-width: 375px) {
                max-width: 250px;
            }

            @media (min-width: @screen-width-tablet) {
                max-width: 400px;
            }
        }

        img {
            border-radius: 50%;
            object-fit: cover;
            height: 65px;
            width: 65px;
        }

        a {
            text-decoration: underline;
        }
    }

    #js-question-counter {
        width: 44px;
        height: 44px;
        border-radius: 50%;
        border: 1px solid #000;
        justify-content: center;
        align-items: center;
        text-align: center;
        font-weight: 600;
        position: absolute;
        top: 10px;
        left: 20px;

        @media (min-width: @screen-width-desktop) {
            top: 0px;
            left: 30px;
        }

        @media (min-width: @screen-width-xl-desktop) {
            left: -40px;
            top: 5px;
        }
    }

    &__summary {
        margin: auto;

        @media (max-width: @screen-width-desktop) {
            flex-direction: column;
        }

        .container {
            padding: 0;

            .row {
                justify-content: space-around;

                @media (min-width: @screen-width-desktop) {
                    justify-content: space-between;
                }
            }
        }

        .left {
            background-image: linear-gradient(to bottom, #ffffff, #ffffff);

            .title {
                font-size: 20px;
                padding-bottom: 10px;

                @media (min-width: @screen-width-desktop) {
                    font-size: 30px;
                    padding-bottom: 20px;
                }
            }

            .intro {
                font-size: 16px;

                @media (min-width: @screen-width-desktop) {
                    font-size: 22px;
                }
            }

            .container {
                .row {
                    a {
                        text-align: center;
                    }
                }
            }
        }

        .right {
            max-width: 625px;
        }
    }
}

.send-email {
    padding-top  : 30px;

    @media (min-width: @screen-width-desktop) {
        padding-top   : 30px;
        padding-bottom: 20px;
    }

    &__form {
        #js-calculator_result_send-email-error p {
            color: red;
        }
    }

    input[type="email"] {
        background: #fff;
        height    : 50px;
        width     : 100%;
    }
}