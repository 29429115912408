.checklistpage {
    padding: 20px;
    max-width: 895px;
    margin: auto;

    .container {
        padding-bottom: 30px;

        .row {
            .btn {
                margin-right: 8px;
            }

            p {
                padding-top: 38px;
            }
        }
    }

    &__entry {
        .container {
            h1 {
                color: black;
            }
        }
    }


    &__list-heading {
        font-weight: bold;
        font-size: 22px;
        padding-bottom: 10px;

        @media (min-width: 992px) {
            font-size: 24px;
        }
    }

    &__list-heading-description {
        display: flex;
        font-weight: bold;
        font-size: 12px;
        padding-bottom: 8px;
        justify-content: space-between;

        @media (min-width: 992px) {
            justify-content: initial;
            font-size: 13px;
        }

        .list-heading-item-titleandradio {
            @media (min-width: 992px) {
                width: 300px;
            }
        }

        .list-heading-item-comment {
            display: none;

            @media (min-width: 992px) {
                display: block;
                // width: 375px; // with date
                width: 445px;

                &.shoppinglistitem {
                    width: 455px;
                }
            }
        }

        span:first-of-type {
            margin-right: 7px;
        }
    }

    &__list-heading-item {
        min-height: 70px;
        justify-content: space-between;
        align-content: center;
        background-color: white;
        border-bottom: 1px solid #e8e8e8;

        @media (min-width: 992px) {
            justify-content: initial;
        }

        .list-heading-item-titleandradio {
            display: flex;
            padding: 10px;
            width: 60%;

            @media (min-width: 992px) {
                padding: 20px;
                padding-left: 20px;
                width: 300px;
            }

            &.shoppinglistitem {
                order: 1;
                padding: 0;
                position: relative;
                top: -15px;

                @media (min-width: 992px) {
                    padding: 20px;
                    position: initial;
                }
            }
        }

        .list-heading-item-title {
            font-size: 12px;
            font-weight: bold;
            // align-self: center;
            width: auto;
            padding-left: 7px;

            @media (min-width: 992px) {
                font-size: 16px;
                align-self: center;
            }

            &.shoppinglistitem {
                padding-left: 20px;
                padding-top: 20px;

                @media (min-width: 992px) {
                    padding-left: 7px;
                    padding-top: 0px;
                }
            }
        }

        .list-heading-item-comment {
            font-size: 10px;
            width: 70%;
            padding-left: 36px;
            padding-right: 20px;
            padding-bottom: 10px;
            order: 3;

            @media (min-width: 992px) {
                // width: 355px; // with date
                width: 425px;
                padding: 20px;
                padding-left: 0px;
                font-size: 14px;
                order: 2;
            }

            &.shoppinglistitem {
                width: 75%;
                position: relative;
                top: -20px;
                left: 4px;

                @media (min-width: 768px) {
                    width: 80%;
                }

                @media (min-width: 992px) {
                    order: 2;
                    width: 415px;
                    position: initial;
                }
            }
        }

        .list-heading-item-timeanddate {
            padding: 10px;
            display: flex;
            order: 2;
            justify-content: space-between;
            // width: 100px; With date
            width: 70px;

            @media (min-width: 992px) {
                padding: 20px;
                justify-content: initial;
                order: 3;
                width: auto;
                flex-wrap: wrap;
            }

            div {
                display: flex;
                font-size: 12px;
                font-weight: bold;
                flex-basis: 85px;

                @media (min-width: 992px) {
                    flex-basis: auto;
                }

                input:first-of-type {
                    @media (min-width: 992px) {
                        margin-right: 10px;
                    }

                    &.shoppinglistitem {
                        margin-left: 6px;
                        margin-right: 6px;
                    }
                }
            }

            span {
                display: block;

                @media (min-width: 992px) {
                    display: none;
                }
            }

            &.shoppinglistitem {
                padding-left: 0px;
                width: auto;

                @media (min-width: 992px) {
                    order: 3;
                    padding-left: 25px;
                    padding-right: 0;
                }
            }
            // .btn-increment{
            // }
            .btn-reduce {
                opacity: 0.5;
            }
        }

        .round {
            position: relative;
        }

            .round label {
                background-color: #f6f6f6;
                border: 1px solid @bordergrey;
                border-radius: 50%;
                cursor: pointer;
                height: 20px;
                left: 0px;
                position: absolute;
                top: 0px;
                width: 20px;

                @media (min-width: 992px) {
                    top: 9px;
                    left: -7px;
                }

                &.shoppinglistitem {
                    top: 26px;
                    left: 10px;

                    @media (min-width: 992px) {
                        top: 9px;
                        left: -7px;
                    }
                }
            }

                .round label:after {
                    border: 2px solid #fff;
                    border-top: none;
                    border-right: none;
                    content: "";
                    height: 6px;
                    left: 5px;
                    opacity: 0;
                    position: absolute;
                    top: 5px;
                    transform: rotate(-45deg);
                    width: 9px;
                }

            .round input[type="checkbox"] {
                visibility: hidden;
            }

                .round input[type="checkbox"]:checked + label {
                    background-color: @peagreen;
                    border-color: @peagreen;
                }

                    .round input[type="checkbox"]:checked + label:after {
                        opacity: 1;
                    }

        input[type=text], input[type=number] {
            background-color: #f6f6f6;
            border: 1px solid @bordergrey;
            text-align: center;
            width: 33px;
            height: 32px;
            border-radius: 4px;

            @media (min-width: 992px) {
                width: 70px;
                height: 40px;
            }

            &.shoppinglistitem {
                width: 45px;
            }
        }
    }

    &__planningTab {
        display: none;

        &__totaltime{
            margin-left: -15px;
        }
    }

    .modal-content {
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        /* height          : calc(100% - 101px);*/
        /*overflow-y       : auto;*/
        @media (min-width: @screen-width-tablet) {
            height: calc(100% - 120px);
        }
    }

    .question {
        &__heading__white {
            color: white;
            font-size: 34px;

            @media (min-width: @screen-width-tablet) {
                font-size: 60px;
            }
        }

        &__description {
            max-width: 610px;
            width: calc(100% - 22px);
            font-size: 14px;
            margin: auto;
            text-align: center;
            padding-bottom: 12px;

            @media (min-width: @screen-width-tablet) {
                font-size: 16px;
                padding-bottom: 60px;
            }

            p {
                font-size: 14px;

                @media (min-width: @screen-width-tablet) {
                    font-size: 16px;
                }
            }
        }

        &__description__white {
            color: white;
            font-size: 18px;

            @media (min-width: @screen-width-tablet) {
                font-size: 22px;
            }
        }

        &__moreinformation {
            padding-top: 10px;
            text-align: left;

            @media (min-width: @screen-width-tablet) {
                padding-top: 100px;
                padding-bottom: 0px;
            }
        }

        &__image {
            display: flex;
            justify-content: center;

            picture {
                max-width: 60%;

                @media (min-width: @screen-width-tablet) {
                    max-width: initial;
                }

                img {
                    max-width: 160px;

                    @media (min-width: @screen-width-tablet) {
                        max-width: 100%
                    }
                }
            }
        }

        &__navigate {
            position: absolute;
            bottom: 0;
            width: 100%;
        }
    }

    .replies {
        max-width: 700px;

        @media (min-width: @screen-width-tablet) {
            flex-direction: row;
        }

        .reply {
            &.has-image {
                height: 160px;
                display: flex;
                justify-content: space-evenly;
                align-items: center;

                @media (min-width: @screen-width-tablet) {
                    width: 295px;
                }

                a {
                    display: flex;
                    justify-content: center;
                    flex-wrap: wrap;
                    width: calc(100% - 20px);
                    height: calc(100% - 30px);

                    @media (min-width: @screen-width-tablet) {
                        width: calc(100% - 70px);
                    }

                    picture {
                        width: calc(100% - 20px);
                    }
                }

                p {
                    padding-bottom: 4px;
                }

                span {
                    font-size: 12px;
                }
            }
        }
    }

    .input-label {
        font-weight: bold;
        padding-top: 12px;
        padding-bottom: 6px;
        font-size: 13px;


        @media (min-width: @screen-width-tablet) {
            padding-top: initial;
            font-size: 16px;
        }
    }

    .input {
        background-color: white;
        width: 100%;
        font-size: 16px;

        @media (min-width: @screen-width-tablet) {
            height: 60px;
            width: 150px;
        }

        &.disabled {
            color: black;
            font-weight: bold;
            background: rgba(162, 194, 162, 0.4);
        }
    }

    .seperator {
        height: 0px;
        font-weight: bold;
    }

    .box {
        min-width: 76px;
        height: 50px;
        background-color: lightgray;
        border-radius: 4px;
        align-self: flex-end;
        display: flex;
        align-items: center;
        justify-content: center;

        @media (min-width: @screen-width-tablet) {
            min-width: initial;
            width: 56px;
            height: 60px;
        }

        &.hide-tablet-down {
            display: none;

            @media (min-width: @screen-width-tablet) {
                display: flex;
            }
        }

        div {
            font-weight: bold;
        }
    }

    .step {
        max-width: 550px;
        margin: auto;


        .m2 {
            display: inline-block;
            position: relative;
        }

        .reply {
            width: 100%;

            @media (min-width: @screen-width-tablet) {
                width: initial;
            }
        }
    }

    .step1,
    .step6 {
        max-width: 610px;

        .input {
            width: 100%;
        }
    }

    .step2,
    .step3,
    .step4,
    .step5 {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-evenly;
        align-items: center;

        @media (min-width: @screen-width-tablet) {
            flex-wrap: nowrap;
        }
    }

    .step3,
    .step5 {
        max-width: 750px;
    }

    .step7 {
        display: flex;
        align-items: center;
        flex-flow: column;


        &__btn {
            width: 100%;
            background-color: white;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-flow: column;

            @media (min-width: @screen-width-tablet) {
                max-width: 400px;
            }

            &.upload {
                height: 110px;

                form {
                    input[type="file"] {
                        display: none;
                    }

                    label {
                        display: flex;
                        flex-direction: column;
                        align-content: center;
                        justify-content: center;
                    }
                }

                @media (min-width: @screen-width-tablet) {
                    height: 140px;
                }

                img {
                    width: 30px;
                    height: 30px;
                    margin: auto;

                    @media (min-width: @screen-width-tablet) {
                        width: 40px;
                        height: 40px;
                    }
                }

                p {
                    margin: auto;
                }

                span {
                    color: @brand-primary;
                    font-size: 12px;
                    margin: auto;

                    @media (min-width: @screen-width-tablet) {
                        font-size: 14px;
                    }
                }
            }

            &.preview {
                flex-flow: row;
                justify-content: flex-start;
                height: 50px;
                border: solid 1px #e8e8e8;
                display: none;

                #checklist-img-remove {
                    margin-left: auto;
                    margin-right: 15px;
                    background-image: url(/Resources/Graphics/close-icon.svg);
                    width: 10px;
                    height: 10px;
                    background-size: 10px;
                    background-repeat: no-repeat;
                }

                img {
                    height: 40px;
                    width: 40px;
                    margin: 5px;
                }

                span {
                    margin: 5px;
                    font-size: 14px;
                }
            }
        }

        p {
            font-weight: bold;
            margin: 0;
            font-size: 12px;

            @media (min-width: @screen-width-tablet) {
                font-size: 16px;
            }
        }

        .skip {
            height: 46px;
            margin-top: 10px;

            @media (min-width: @screen-width-tablet) {
                height: 68px;
                margin-top: 20px;
            }
        }
    }

    .step8 {
        display: flex;
        align-items: center;
        flex-flow: column;

        #checklist-project-complete-img-preview {
            margin-top: 10px;
            display: none;
            width: 76px;
            height: 76px;
            border-radius: 50%;
            object-fit: cover;

            @media (min-width: @screen-width-tablet) {
                margin-top: 20px
            }
        }

        p {
            font-weight: bold;
            font-size: 20px;
            margin-top: 5px;

            @media (min-width: @screen-width-tablet) {
                font-size: 24px;
            }
        }

        button {
            margin-top: 150px;

            @media (min-width: @screen-width-tablet) {
                margin-top: 50px
            }
        }
    }

    .gables {
        width: 100%;
        display: flex;
        align-items: center;

        @media (min-width: @screen-width-tablet) {
            display: none;
        }

        .seperator {
            padding-left: 6px;
            padding-right: 6px;
            line-height: 2.5;
        }
    }

    .checklistpage__navigate {
        height: 10vh;
        width: 100%;
        position: fixed;
        bottom: 0;
        left: 0;
        background-color: white;
        border: solid 1px #e8e8e8;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 100;

        @media (min-width: @screen-width-tablet) {
            height: 80px;
        }
    }
    //Hides arrows from input type="number"
    /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
    /* Firefox */
    input[type=number] {
        -moz-appearance: textfield;
    }

    .send-email {
        button {
            margin-top: 20px;
        }
    }

    &__extrablock{
        padding:20px;
        background-color: #E9F0E9;
        display: flex;
        img{
            height: 48px;
            width: 48px;
            border-radius: 50%;
            
            margin-right: 15px;
            @media (min-width: @screen-width-tablet) {
                align-self: center;
                height: 87px;
                width: 87px;
            }
        }

        &__info{
            p{
                font-size: 20px;
                font-weight: bold;
                font-family: Caslon224Std;
                line-height: normal;
            }
            ul{
                list-style-type: none;
                margin: 0;
                padding: 0;
                overflow: hidden;
                padding-bottom: 20px;
                @media (min-width: @screen-width-tablet) {
                    padding-bottom: 10px;
                }
            }
            li{
                float: left;
                font-size: 16px;
                padding-right: 20px;
                margin-top: 10px;
                @media (min-width: @screen-width-tablet) {
                    margin-top: initial;
                }
                
                &:before {
                    content        : "";
                    background     : url('/Resources/Graphics/icon-check.svg');
                    background-size: 18px;
                    width: 18px;
                    height: 18px;
                    display        : inline-block;
                    margin-right    : 6px;
                    margin-bottom: -3px;
                }
            }
            a {
                color      : @black;
                font-weight: bold;

                &:after {
                    content        : "";
                    background     : url('/Resources/Graphics/right-arrow-icon.svg');
                    background-size: 12px;
                    width          : 12px;
                    height         : 10px;
                    display        : inline-block;
                    margin-left    : 5px;
                }
            }
        }
    }
}