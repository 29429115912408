.smallsplitblock {
    padding: 40px 0;
    display: block;

    &__container {
        max-width: 605px;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        margin: 0 auto;

        &.is-linked {
            cursor: pointer;
        }
    }

    &__heading {
        width: 100%;
    }

    &__left {
        padding: 0;
    }

    &__right {
        padding: 0;
        background: #fff;
        padding: 25px 20px;
        display: flex;
        align-items: center;
        margin-top: -3px;

        @media (min-width: @screen-width-desktop) {
            margin-top: 0;
        }

        p {
            font-size: 18px;
            line-height: 1.3;
            letter-spacing: -0.57px;
            font-weight: normal;
            font-family: @font-family-serif;
            font-weight: bold;
            margin: 0 0 15px 0;

            @media (min-width: @screen-width-lg-desktop) {
                font-size: 20px;
            }
        }
    }

    &__link {
        .sans-medium;
    }
}

.articlepage__content {
    .smallsplitblock__right {
        @media (min-width: @screen-width-desktop) {
            height: 183px;
        }

        @media (min-width: @screen-width-lg-desktop) {
            height: 202px;
        }
    }
}