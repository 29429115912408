.login-signup-modal {
    padding: 0;
    border-radius: 0;

    &__close {
        display: none;

        @media (max-width: @screen-width-tablet ) {
            display: block;
            position: absolute;
            top: 12px;
            right: 15px;
        }
    }

    @media (min-width: @screen-width-tablet ) {
        max-height: 600px;
        overflow-y: auto;
    }

    @media (max-width: @screen-width-tablet ) {
        width: 100%;
        height: 100%;
        overflow-y: scroll;
    }

    .information-message {
        margin: 0 0 20px 0;
        padding: 15px 20px;
        background-color: @highlight;
        font-size: 14px;

        @media (min-width: @screen-width-tablet ) {
            padding: 24px 65px;
            font-size: 16px;
        }
    }

    .tabs-container {
        background-color: #fff;
    }

    .tabs-header {
        display: flex;
        justify-content: flex-start;
        flex-direction: row;


        @media (max-width: @screen-width-tablet ) {
            border-top: solid 1px #f1f1f1;
            margin-top: 40px;
        }

        li {
            .sans-medium;
            border-radius: 0;
            width: 50%;
            border-radius: 0;
            width: 50%;
            margin: 0;
            border: none;
            background: #eaeaea;
            height: 56px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 14px;
            line-height: 1.57;
            color: black;

            @media (min-width: @screen-width-tablet) {
                font-size: 16px;
                line-height: 1.5;
            }

            &.current-tab,
            &.default-tab,
            &:focus,
            &:active,
            &:visited {
                border: none;
                background: #fff;
                color: black;
            }

            &:hover {
                background: #e5e3e3;
                border: none;
                color: black;

                @media (max-width: @screen-width-tablet) {
                    background: #fff;
                }
            }
        }
    }

    .tabs {
        .tab {
            padding: 35px 30px;
            margin: 0;
            text-align: center;
        }
    }

    &__loginbuttons {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        padding: 20px 0 10px 0;

        .btn {
            width: 280px;
            margin: 0 0 15px;
        }
    }

    .arrow-link {
        font-size: 13px;

        @media (min-width: @screen-width-tablet ) {
            font-size: 16px;
        }

        &:after {
            background-size: 10px;
            width: 10px;
            height: 9px;

            @media (min-width: @screen-width-tablet ) {
                background-size: 12px;
                width: 12px;
                height: 10px;
            }
        }
    }

    input[type="number"] {
        border-radius: 4px;
        border: solid 1px @bordergrey;
        background: @backgroundgrey;
        padding: 15px;
    }

    .checkbox {
        font-size: 16px;
        text-align: left;
    }

    .message {
        margin-bottom: 20px;
    }

    &__content a {
        text-decoration: underline;
    }
}
.login-signup-modal.signup_or_register {
    max-height: none;
    overflow-y: initial;
    max-width: 600px;
    margin: 0 auto 20px auto;

    .tabs-header {
        margin-top: 0px;
        border: 0px;
    }
}

.agree-terms-label{
    .sans-regular;
    font-size: 14px;
    line-height: 1.57;
    vertical-align: top;

    @media (min-width: @screen-width-tablet ) {
        font-size: 16px;
        line-height: 1.75;
    }
    
    a{
        color: #000;
        text-decoration: underline;
    }
}
.forgot-password-link{
    text-decoration: underline;
}