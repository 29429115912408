.videoblock {
  margin-bottom: 1.5rem;
  background-color: @off-white;

  &__list {
    display: grid;
    grid-gap: 1rem;
    margin-left: auto;
    margin-right: auto;
    max-width: 1300px;
    background-color: @off-white;
    
    &.video-4 {
      grid-template-columns: 1fr;

      @media (min-width: @screen-width-desktop) {
        grid-template-columns: repeat(2, 1fr);
      }

      @media (min-width: @screen-width-lg-desktop) {
        grid-template-columns: repeat(4, 1fr);
      }
    }

    &.video-3 {
      grid-template-columns: 1fr;

      @media (min-width: @screen-width-desktop) {
        grid-template-columns: repeat(3, 1fr);
      }
    }

    &.video-2 {
      grid-template-columns: 1fr;

      @media (min-width: @screen-width-desktop) {
        grid-template-columns: repeat(2, 1fr);
      }
    }

    &.video-1 {
      grid-template-columns: 1fr;
    }

    & button {
      position: relative;
      background: none;
      border: none;
      padding: 0;
      height: auto;
      margin: 0 !important;
      width: 100% !important;

      img {
        width: 100%;
        clip-path: polygon(0.4% 0.4%, 99.6% 0.4%, 99.6% 99.6%, 0.4% 99.6%);
      }

      svg {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        transition: transform 0.25s ease;
        transform-origin: center;

        & circle {
          fill: #fff;
          opacity: 0.25;
        }

        & path {
          transform-origin: center;
          transform: scale(3);
        }
      }

      &:hover {
        svg {
          transform: translate(-50%, -50%) scale(1.1);

          & circle { 
            opacity: 0.25;
          }
        }
      }
    }
  }

  & iframe {
    width: 100% !important;
    height: auto !important;
    aspect-ratio: 16 / 9;
    clip-path: polygon(0.4% 0.4%, 99.6% 0.4%, 99.6% 99.6%, 0.4% 99.6%);
  }
}
