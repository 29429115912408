.navigation {
    padding: 0;
    position: relative;

    &-container {
        display: flex;
        flex-wrap: wrap;
        padding: 10px 15px 20px;

        @media (width: @screen-width-ipad) {
            max-width: 810px;
        }

        @media (min-width: @screen-width-desktop) {
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
        }

        .menu {
            font-size: 9px;
            text-transform: uppercase;
            height: 11px;
            display: flex;
            margin-left: 2px;
            justify-content: center;
            align-items: center;

            @media (min-width: @screen-width-lg-desktop) {
                display: none;
            }
        }

        &__nosearchbar {
            padding: 10px 15px 0px;
            transition: all 0.3s;
        }

        form.navigation-item {
            @media (max-width: 767px) {
                order: 4;
            }
        }


        .navigation-item {
            display: flex;
            flex-direction: row;
            align-items: center;

            &__icon {
                width: 20px;
                position: relative;
                filter: brightness(0) saturate(100%);

                @media (min-width: @screen-width-desktop) {
                    width: 30px;
                    height: 30px;
                    margin-bottom: 5px;
                }

                img {
                    display: block;
                }
            }

            &__icon-white {
                filter: invert(93%) sepia(93%) saturate(0%) hue-rotate(247deg) brightness(107%) contrast(108%);
            }

            span {
                @media (max-width: @screen-width-lg-desktop) {
                    display: none;
                }
            }
        }

        .navigation-quicklinks {
            justify-content: flex-end;
            margin: 0;
            margin-left: auto;

            @media (max-width: 767px) {
                order: 3;
            }

            .navigation-quicklink {
                color: #000;
                text-decoration: none;
                font-size: 12px;
                border: none;
                background: none;
                padding: 0;
                display: flex;
                align-items: center;
                flex-direction: column;
                margin-right: 12px;

                &.login-menu-button {
                    height: auto;
                }

                @media (min-width: @screen-width-desktop) {
                    margin-right: 20px;
                }
            }
        }

        &__logotype {
            @media (max-width: @screen-width-desktop) {
                width: 105px;
                position: absolute;
                margin-left: auto;
                margin-right: auto;
                left: 0;
                right: 0;
                top: initial;
                text-align: center;
            }

            a {
                display: block;
            }

            img {
                max-width: none;
                height: 25px;
                display: block;

                @media (min-width: @screen-width-desktop) {
                    height: 35px;
                    margin-left: 10px;
                }

                @media (min-width: @screen-width-lg-desktop) {
                    height: 48px;
                    margin-left: 0px;
                }
            }
        }

        &__wishlist {
            position: relative;

            .js-wishlist-count-active {
                position: absolute;
                top: -5px;
                left: 11px;
                border-radius: 50%;
                width: 17px;
                height: 17px;
                background: @success;
                font-size: 10px;
                font-weight: 600;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.45;
                letter-spacing: -0.31px;
                text-align: center;
                color: #fff;
                display: flex;
                justify-content: center;
                align-items: center;

                @media (min-width: @screen-width-xl-desktop) {
                    left: 35px;
                }
            }
        }

        &__cart {
            position: relative;

            .js-minicart-count-active {
                position: absolute;
                top: -5px;
                left: 11px;
                border-radius: 50%;
                width: 17px;
                height: 17px;
                background: @success;
                font-size: 10px;
                font-weight: 600;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.45;
                letter-spacing: -0.31px;
                text-align: center;
                color: #fff;
                display: flex;
                justify-content: center;
                align-items: center;

                @media (min-width: @screen-width-xl-desktop) {
                    left: 35px;
                }
            }
        }
    }

    a {
        color: #ffffff;
        text-decoration: none;
        font-size: 14px;
    }

    ul {
        padding: 0;
        margin: 0;
    }

    &__searchinput {
        @media (max-width: 767px) {
            width: 100%;
            margin-top: 8px;
        }
    }

    &__menu {
        display: none;
        background-color: #fff;

        @media (min-width: @screen-width-lg-desktop) {
            display: block;
        }

        .center {
            display: flex;
            justify-content: center;
        }

        ul {
            display: flex;
            flex-direction: row;
            list-style: none;
            flex-wrap: wrap;
            justify-content: center;
            padding: 0 30px;

            li {
                a {
                    padding: 12px 15px 10px 15px;
                    color: #000;
                    font-size: @font-size-large;
                    display: inline-block;
                    line-height: 1.5;

                    &:hover {
                        text-decoration: none;
                    }
                }

                &.toplevel-item {
                    height: 52px;

                    &:hover {
                        border-bottom: 2px solid #000;
                    }
                }
            }
        }
    }

    .level1 {
        &__teasers {
            padding-top: 30px;

            ul li a {
                display: flex;
                flex-direction: column;
                align-items: center;

                picture img {
                    width: 290px;
                    height: 170px;
                    object-fit: cover;
                }

                span {
                    font-weight: bold;

                    &:after {
                        content: "";
                        background-image: url(/Resources/Graphics/arrow-right.svg);
                        background-size: 12px;
                        background-repeat: no-repeat;
                        width: 12px;
                        height: 10px;
                        position: relative;
                        display: inline-block;
                        margin-left: 6px;
                    }
                }
            }
        }
    }

    .level2 {
        background: #fff;
        display: none;
        min-height: 310px;
        justify-content: center;
        position: absolute;
        left: 0;
        z-index: 100;
        width: 100%;

        &.expanded-menu {
            display: flex;
        }

        ul {
            li {
                a {
                    color: @accent-second;
                }
            }
        }

        .level2-item {
            a {
                svg {
                    transform: rotate(90deg);
                    fill: @light;
                    float: right;
                    margin-top: 13px;
                }
            }
        }

        .level2__left {
            width: 335px;

            @media (min-width: 1441px) {
                width: 308px;
            }

            ul {
                flex-direction: column;
                padding: 0;
                margin: 0;

                li {
                    margin: 0;
                    padding: 0;

                    &.menu-active {
                        background: @light;

                        a {
                            svg {
                                fill: @accent-second;
                            }
                        }
                    }

                    a {
                        .sans-medium;
                        padding: 18px 60px;
                        display: block;
                        width: 100%;
                        font-size: 16px;
                        line-height: 1.87;
                        color: @accent-second;

                        &:hover {
                            background: @light;

                            svg {
                                fill: @accent-second;
                            }
                        }
                    }
                }
            }
        }

        .level2__teaser {
            width: 660px;
            background: #fff;
            padding-right: 10px;
        }

        ul.level2__teaser__item {
            margin-top: 25px;

            li {
                flex-direction: column;
                margin: 0;
                padding: 0;
                width: 50%;
                padding: 15px;

                a {


                    span {
                        display: block;
                        text-align: center;
                        font-size: 16px;
                        line-height: 1.5;
                        padding-top: 2px;
                    }

                    img {
                        width: 100%;
                    }
                }
            }
        }
    }

    .level3__middle {
        width: 430px;
        background: #fff;
    }

    .level3 {
        display: none;
        flex-direction: column;
        padding: 30px 100px;

        li {
            padding: 0;
            margin: 0;

            a {
                .sans-medium;
                padding: 10px 20px;
                display: inline-block;
                font-size: 16px;
                line-height: 1.5;
            }
        }
    }

    &__breadcrumbs {
        font-size: 0.8em;
        background-color: @gray-lighter;
        padding: 5px 8px;

        a {
            color: @gray-light;

            &:hover {
                color: @gray;
                font-weight: bold;
            }
        }
    }
}

.toplevel-link:hover + .level2 {
    display: flex;
}

.toplevel-item:hover {
    .level2 {
        display: flex;
    }
}

.mobilenav-flyin {
    z-index: 100;
    position: fixed;
    top: 0;
    bottom: 0px;
    left: -100%;
    width: 240px;
    height: 100%;
    background: #fff;
    overflow-x: hidden;
    overflow-y: auto;
    padding: 0;
    -webkit-transition: left 0.5s ease;
    -moz-transition: left 0.5s ease;
    -ms-transition: left 0.5s ease;
    -o-transition: left 0.5s ease;
    transition: left 0.5s ease;

    @media (min-width: 1023px) {
        display: none;
    }

    @media (min-width: @screen-width-xl-desktop) {
        padding: 60px 30px;
    }

    &.expanded {
        left: 0;
    }

    &-closebtn {

        height: 16px;
        position: absolute;
        top: 11px;
        right: 10px;
        background: none;
        border: none;
        padding: 0;
        cursor: pointer;
        border-radius: 0;
        z-index: 10;
        color: black;

        span {
            text-transform: uppercase;
            font-size: 9px;
            line-height: 1.78;
            position: relative;
            top: 0px;
            margin-right: 3px;
        }

        svg {
            height: 14px;
            position: relative;
            top: 3px;
        }
    }

    .nav-top {
        height: 45px;
        width: 240px;
        position: relative;
        top: 0;
        left: 0;
        background: @light;
    }

    a {
        color: black;
        font-size: @font-size-large;
        line-height: 1.5;
        text-transform: none;
    }

    .slide-menu {
        width: 100%;
        height: 100vh;
        background-color: #fff;
        overflow: visible;

        ul {
            li {
                padding: 15px 23px;

                a {
                    display: flex;
                    justify-content: space-between;
                    text-transform: none;
                }
            }

            a {
                text-transform: none;
            }

            .mobilenav-flyin__teaser {
                display: flex;
                background-color: @backgroundgrey;
                padding-bottom: 8px;
                padding-top: 0px;
                border-bottom: none;

                a {
                    background-color: white;
                    width: 100%;
                    padding-right: 10px;
                    justify-content: left;

                    img {
                        width: 40px;
                        height: 40px;
                    }

                    span {
                        align-self: center;
                        padding-left: 10px;
                        font-size: 12px;
                        font-weight: 600;
                    }
                }

                &__first {
                    padding-top: 17px;
                }
            }
        }

            ul.extra-links {
                padding: 20px 0 0 0;
            }

        .extra-link {
            border: none;
            background-color: #fff;
            text-align: center;

            a {
                font-size: @font-size-base;
                line-height: 1.57;
            }
        }

        ul.slide-menu__submenu--active li:first-child {
            border: none;
            padding: 0 23px;
        }

        .slide-menu__backlink {
            position: absolute;
            top: -27px;
            font-size: 10px;
            text-transform: uppercase;
            padding-left: 12px;
            margin-left: -10px;

            &:before {
                content: "";
                background: url(/Resources/Graphics/arrow-left.svg);
                background-repeat: no-repeat;
                background-size: 5px;
                width: 6px;
                height: 10px;
                position: absolute;
                left: 0;
                top: 2px;
                transform: none;
            }
        }
    }
}

.js-toggle-searchbar{
    padding-left:15px;

    @media (min-width: @screen-width-desktop) {
        display: none;
    }
}

.js-toggle-mobilenav-flyin {
    display: inline-block;
    border-radius: 0;
    padding: 0;
    font-size: 8px;
    text-transform: uppercase;
    color: #000;
    font-weight: normal;
    background: none;
    width: 25px;
    height: 25px;
    position: relative;

    @media (min-width: @screen-width-lg-desktop) {
        display: none;
    }

    i {
        width: 24px;
        height: 1px;
        background: #000;
        margin-bottom: 5px;
        display: block;
    }
}

#nav-overlay {
    width: 100%;
    height: 100%;
    display: none;
    position: fixed;
    z-index: 99;
    top: 317px;
    background: rgba(172, 171, 171, 0.4);
}

.toggle-content {
    display: none;
    height: 0;
    overflow: hidden;
    transition: height 350ms ease-in-out;
}

.toggle-content.is-visible {
    display: block;
    height: auto;
}

.jsSearchIcon{
    opacity:0;

    &.fade-in{
        opacity: 1;
        transition: all 2s;        }
}