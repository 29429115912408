.customerprofilepage {
    padding-bottom: 40px;

    &__hero {
        position: relative;
        text-align: center;

        @media (max-width: @screen-width-desktop) {
            margin: 0 -15px;
            background: #fff;
        }

        .hero-left {
            padding: 40px 0 10px 0;

            @media (max-width: @screen-width-desktop) {
                padding: 40px 20px 10px 20px;
            }

            @media (min-width: @screen-width-lg-desktop) {
                position: absolute;
                width: 460px;
                background: rgba(255,255,255,0.9);
                padding: 40px 40px 30px 40px;
                top: 50%;
                left: 20px;
                transform: translateY(-50%);
            }

            @media (min-width: @screen-width-xl-desktop) {
                left: 50px;
            }

            @media (min-width: @screen-width-xxl-desktop) {
                left: 75px;
            }

            p {
                font-size: 18px;

                @media (min-width: @screen-width-lg-desktop) {
                    font-size: 16px;
                }
            }
        }

        .hero-right {
            padding: 0 0 40px 0;
            text-align: center;
            font-size: 16px;
            font-weight: bold;

            @media (min-width: @screen-width-lg-desktop) {
                position: absolute;
                top: 50%;
                right: 20px;
                transform: translateY(-50%);
                background: rgba(255,255,255,0.9);
                padding: 30px 20px;
                width: 234px;
            }

            @media (min-width: @screen-width-xl-desktop) {
                right: 50px;
            }

            @media (min-width: @screen-width-xxl-desktop) {
                right: 75px;
            }

            span {
                display: block;
                padding: 10px 0;

                > strong {
                    font-family: @font-family-serif;
                    font-weight: bold;
                    font-size: 30px;
                    position: relative;
                    top: 4px;
                    display: block;
                }
            }
        }
    }

    &__campaign {
        margin: 0 -15px;
        padding-top: 40px;

        @media (min-width: @screen-width-desktop) {
            padding-top: 70px;
        }
    }
}

.profile {
    &__links {
        position: relative;
        padding: 20px 0 20px 0;

        @media (min-width: @screen-width-desktop) {
            padding: 40px 0;
        }

        ul {

            @media (min-width: 576px) {
                list-style: none;
                padding: 0;
                margin: 0;
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }

        li {
            a {
                @media (min-width: 576px) {
                    border-radius: 16px;
                    background: @green;
                    border: 1px solid @green;
                    font-size: 12px;
                    font-weight: 600;
                    letter-spacing: -0.34px;
                    color: #000;
                    margin: 0 4px;
                    padding: 7px 15px;
                    transition: background .25s ease-in;
                }
            }

            &.current, &:hover {
                a {
                    @media (min-width: 576px) {
                        background: #fff;
                        border-color: @green;
                    }
                }
            }

            &.current a {
                pointer-events: none;
            }
        }
    }

    &__logout {
        @media (min-width: 576px) {
            position: absolute;
            right: 0;
            top: 40px;
            background: none;
            padding: 0;
            border-radius: 0;
            font-size: 16px;
            color:#000;
        }
    }
}
