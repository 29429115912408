.flowboxblock {
    padding: 40px 0;
    padding-bottom: 120px;
    &__text {
        text-align: center;

        h2 {
            margin-top: 30px;
        }
    }

    #js-flowbox-flow {
        margin: 30px 0 0 0;
    }
}
