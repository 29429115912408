.breadcrumbs-container {
    background   : @background;
    padding-top  : 10px;

    .breadcrumbs {

        ul {
            list-style: none;
            padding   : 0;
            margin    : 0;
            display   : flex;

            li {
                .sans-medium;
                position      : relative;
                font-size     : 11px;
                line-height: 1.45;
                color         : @dark;
                margin-right  : 8px;
                padding-left  : 10px;

                @media (min-width: @screen-width-tablet ) {
                    font-size: 12px;
                    line-height: 1.5;
                }

                &:before {
                    content      : "";
                    width        : 3px;
                    height       : 3px;
                    border-radius: 50%;
                    background   : @accent;
                    display      : block;
                    position     : absolute;
                    left         : 0;
                    top          : 7px;
                }

                a {
                    font-size     : 11px;
                    color         : @accent-second;
                }

                &:first-child {
                    padding-left: 0;

                    &:before {
                        display: none;
                    }
                }

                &.empty {
                    display: none;
                }

                &:last-child {
                    color: @lightgrey;

                    a {
                        color: @lightgrey;
                    }

                    &.empty {
                        display: block;
                    }
                }
            }
        }
    }
}