.addresscollection {
    max-width: 853px;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap; 
}

.addressblock {
    background: #fff;
    padding-top: 15px;
    text-align: center;
    margin: 15px;
    width: 100%;

    @media (min-width: @screen-width-tablet) {
        width: calc(33.3% - 30px);
    }

    &__address {
        border-bottom: 1px solid @backgroundgrey;
        padding-bottom: 15px;

        span {
            display: block;
            margin: 3px 0;
        }

        a {
            padding-top: 7px;
            display: block;
        }
    }

    &__openinghours {
        padding: 15px;
        text-align: left;
        letter-spacing: -0.46px;
        margin-bottom: 5px;
        width: 100%;

        tr {
            td {
                &:first-child {
                    text-align: left;
                }

                &:last-child {
                    text-align: right;
                }
            }
        }
    }

    &__inner {
        height: 100%;
        position: relative;

        h3 {
            padding: 0 15px;
        }

        &__link {
            position: relative;
            width: 100%;
            border-top: 1px solid @backgroundgrey;
            padding-top: 15px;
            bottom: 15px;
        }        
    }
}

.specialhours {
    height: 22px;
    overflow: hidden;
    margin-bottom: 27px;
    transition: height 0.4s linear;

    .expandlink {
        cursor: pointer;
        padding:0!important;
    }

    &.specialHour-expanded {
    }
}

.addressblock-wide {
    background: #fff;
    margin-bottom: 30px;

    > .row {
        margin-right: 0;
        margin-left: 0;
    }

    section {
        text-align: center;
        padding: 30px 0;

        &:first-child {
            border-bottom: 1px solid @backgroundgrey;

            @media (min-width:@screen-width-tablet ) {
                border-right: 1px solid @backgroundgrey;
                border-bottom: none;
            }
        }

        &:last-child {
            border-top: 1px solid @backgroundgrey;

            @media (min-width:@screen-width-tablet ) {
                border-left: 1px solid @backgroundgrey;
                border-top: none;
            }
        }

        .gap {
            padding: 0 20px;
        }
    }

    span {
        text-align: center;
        display: block;
    }

    .storename {
        font-family: @font-family-serif;
        font-size: 20px;
        text-align: center;
        padding-bottom: 20px;
        display: block;
    }

    .openhours-heading {
        font-weight: normal;
        font-size: 16px;
        font-family: @font-family-sans-serif;
        padding-bottom: 20px;
    }

    a {
        padding: 2px 0;
        display: block;

        &.arrow-link {
            padding: 7px 0 15px 0;
        }
    }

    table {
        margin: 0 auto;

        tr {
            vertical-align: baseline;
        }

        td {
            padding: 4px 0;
        }
    }

    .bold {
        .sans-medium;
    }
}
