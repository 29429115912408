.orderhistorypage {
    padding-bottom: 40px;

    @media (min-width: @screen-width-lg-desktop) {
        padding-bottom: 80px;
    }

    h1 {
        text-align: center;
    }

    .order {
        background: #fff;
        font-size: 12px;
        letter-spacing: -0.46px;
        padding: 10px 0 0 0;
        margin: 0;
        border-bottom: 3px solid @backgroundgrey;

        @media (min-width: @screen-width-desktop ) {
            max-width: 670px;
            margin: 0 auto;
            font-size: 16px;
        }

        &__head {
            margin: 30px 0 0 0;
            background: #fff;
            font-size: 13px;
            font-weight: 600;
            padding: 10px 0;
            border-bottom: 3px solid @backgroundgrey;

            @media (min-width: @screen-width-desktop) {
                max-width: 670px;
                margin: 50px auto 0 auto;
            }
        }

        &__id {
            font-weight: bold;
            display: flex;
            align-items: center;
        }

        &__date, &__sum {
            display: flex;
            align-items: center;
        }

        &__items {
            margin-top: 10px;
            height: 0;
            overflow: hidden;
            transition: height .2s ease-in;
        }

        &__toggle {
            font-size: 22px;
            font-weight: 200;
            text-align: center;
            justify-content: flex-end;
            align-items: end;

            span {
                position: relative;
                top: 0;

                @media (min-width: @screen-width-desktop ) {
                    margin-left: 50px;
                    top: 6px;
                }

                &:before {
                    content: "";
                    background: url(/Resources/Graphics/more.svg);
                    background-repeat: no-repeat;
                    background-size: 14px;
                    width: 14px;
                    height: 15px;
                    position: absolute;
                    top: 0;
                    left: 0;
                }
            }

            &.is-visible {
                span {
                    &:before {
                        content: "";
                        background: url(/Resources/Graphics/less.svg);
                        background-repeat: no-repeat;
                        background-size: 14px;
                        width: 14px;
                        height: 15px;
                    }
                }
            }
        }

        &__item {
            background: @backgroundgrey;
            border-bottom: 2px solid #fff;
            padding: 10px 0;
            margin: 0;
        }

        &__item-count {
            span {
                width: 41px;
                height: 34px;
                border: solid 1px #d6d6d6;
                background-color: #ffffff;
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }

        &__item-image img {
            width: 46px;
            height: auto;

            @media (min-width: @screen-width-desktop) {
                width: 50px;
            }
        }

        &__item-price {
            font-weight: bold;
            font-size: 12px;

            @media (min-width: @screen-width-desktop) {
                text-align: right;
                font-size: 13px;
            }
        }

        &__item-info {
            font-weight: bold;
            font-size: 12px;
            padding: 0;

            @media (min-width: @screen-width-desktop) {
                font-size: 13px;
            }

            span {
                display: block;
            }
        }

        &__status {
            background: @lightgreen;
            margin: 0;
            padding: 15px;
            align-items: center;
            border-bottom: 15px solid #fff;
        }

        &__status-status {
            padding: 0;
            font-weight: bold;
            display: flex;
            justify-content: flex-start;
            align-items: center;

            span {
                padding-left: 5px;
            }
        }

        &__status-price {
            padding: 0;
            text-align: right;
            font-style: italic;
            color: @grey;
            font-size: 12px;

            .total {
                font-weight: bold;
                color: #000;
                font-style: normal;
                margin-top: 2px;
                display: block;
            }
        }
    }
}
