.input {
    height       : 50px;
    border       : 1px solid @bordergrey;
    border-radius: 4px;
    background   : @backgroundgrey;
    width        : 100%;
    padding      : 5px 10px;
    font-size: 16px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

input[type=number] {
    -moz-appearance: textfield;

  }

.quantity-input {
    width           : 56px;
    height          : 50px;
    border-top      : solid 1px @bordergrey;
    border-bottom   : solid 1px @bordergrey;
    border-right    : none;
    border-left     : none;
    background-color: #ffffff;
    text-align      : center;
    font-size: 16px;
}

.select-wrapper {
    position: relative;

    &:after {
        content          : "";
        background       : url(/Resources/Graphics/arrow-left.svg);
        background-size  : 9px;
        background-repeat: no-repeat;
        position         : absolute;
        right            : 20px;
        top              : 17px;
        display          : block;
        width            : 11px;
        height           : 17px;
        transform        : rotateZ(-90deg);
        pointer-events   : none; 
    }
}

select {
    padding           : 15px 20px;
    width             : 100%;
    border-radius     : 4px;
    border            : solid 1px #d6d6d6;
    background-color  : #ffffff;
    font-size         : 16px;
    letter-spacing    : -0.46px;
    -webkit-appearance: none;
    -moz-appearance   : none;
    text-indent       : 1px;
    text-overflow     : '';

    &:focus,
    &:active {
        border : 2px solid @green;
        outline: none;
    }
}

select::-ms-expand {
    display: none;
}

input[type="text"],
input[type="email"],
input[type="password"],
input[type="tel"] {
    border-radius: 4px;
    border       : solid 1px @light-third;
    background-color: fade(@light, 30%);
    padding      : 15px;
    font-size    : 14px;
    height: 46px;

    @media (min-width: @screen-width-tablet) {
        font-size    : 16px;
        height: 50px;
      }
}
input[type=checkbox] {
    background-color: fade(@light, 30%);
    border: 1px solid @light-third;
    border-radius: 2px;
    width: 16px;
    height: 16px;

    @media (min-width: @screen-width-tablet) {
        width: 20px;
        height: 15px;
      }
    @media (min-width: @screen-width-desktop) {
        height: 20px;
    }
}

::placeholder {
    color: @dark-third;
    opacity: 1;
  }

textarea {
    width        : 100%;
    height       : 100px;
    border-radius: 4px;
    border       : solid 1px @bordergrey;
    padding      : 15px;
}

.form-group {
    display       : flex;
    flex-direction: column;
    margin-bottom : 15px;
    text-align    : left;

    &--inline {
        flex-direction: row;
        margin-top    : 10px;
        align-items   : stretch;

        .news-letter-subscription-mail {
            flex: 1;
        }

        button {
            margin       : 0;
            border-radius: 0px 6px 6px 0px;
        }
    }
}

form {
    margin-left : auto;
    margin-right: auto;
}

label {
    font-size     : 11px;
    font-weight   : 500;
    line-height   : 1.45;
    color         : #000;
    margin        : 0 0 3px 0;

    @media (min-width: @screen-width-tablet) {
        font-size     : 12px;
        line-height   : 1.50;
      }
}

.appendMovingDots:after {
    content  : '';
    animation: dots 2s linear infinite
}

@keyframes dots {

    0%,
    20% {
        content: '.'
    }

    40% {
        content: '..'
    }

    60% {
        content: '...'
    }

    90%,
    100% {
        content: ''
    }
}

form {
    margin-left : auto;
    margin-right: auto;
}

.EPiServerForms {
    margin: 0 auto;
    padding: 20px 0;

    @media (min-width: @screen-width-desktop) {
        width: 60%;
        padding: 40px 0;
    }

    INPUT[type=checkbox]:focus {
        outline: 1px solid rgba(0, 0, 0, 0.2);
    }

    INPUT[type=checkbox] {
        background-color: @backgroundgrey;
        border-radius: 2px;
        appearance: none;
        -webkit-appearance: none;
        -moz-appearance: none;
        width: 20px;
        height: 20px;
        cursor: pointer;
        position: relative;
        top: 5px;
        border: solid 1px @bordergrey;
    }

        INPUT[type=checkbox]:checked {
            background-color: @backgroundgrey;
            background: @backgroundgrey url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAKCAIAAAACUFjqAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAAFiUAABYlAUlSJPAAAAAOSURBVChTYxgFJAMGBgABNgABY8OiGAAAAABJRU5ErkJggg==') 4px 4px no-repeat;
        }

    INPUT[type=radio] {
        background-color: #fff;
        border-radius: 10px;
        appearance: none;
        -webkit-appearance: none;
        -moz-appearance: none;
        width: 20px;
        height: 20px;
        cursor: pointer;
        position: relative;
        top: 5px;
        border: solid 1px @bordergrey;
    }

        INPUT[type=radio]:checked {
            background-color: #fff;
            background: #fff url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAIAAAAC64paAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAAFiUAABYlAUlSJPAAAAFFSURBVDhPpZRdj0NAGIUHTXzFxbK2Eb/Vn3Dvp3VvRLT1sReiaOnsWe9ENK0my+PmzPueMwYzJM45m9EPfVVVTdPch/swDKgoiiIrsq7rlmXtlB3ZiIfw6Xyq69r+sGFVVVVUGeu6DtOVP6VpmvuvvajOw0mSaJrmOA4NX1IURdu2vu/TUISRxN1s26bqG8qyxCpEHuHj+ZhnOQSBlYdh6LruaGYQGKIo2pzDjAgEu/W3w/eBqgAvyfM8is1BES1h4hwRBBleAy4qYfqXSQKt6f5jqJQvzcUwDGpHUZSmKeln0IKBNCIIsjiOaTIwPecSMAgr5wjKtBOILMuEWmBuQFAWchUydp+Q46qEWmBu+Nu2kiJduyuNgyAgscRkQATBTZ9q2yaBWr09Nx2MxyOpas7n2yOZF233dCSJ9T8D4h+/IcZ+ASRvjsC8SYw7AAAAAElFTkSuQmCC') -0.7px -1px no-repeat;
        }
}

.Form__Element__Caption {
    font-size     : 13px;
    font-weight   : 600;
    line-height   : 1.31;
    letter-spacing: -0.37px;
    color         : #000;
    margin-bottom : 5px;
    display       : block;
}

.Form__Element {
    padding: 8px 0;
}

.FormTextbox {

    input,
    input [type="text"],
    input[type="email"],
    input[type="password"],
    input[type="tel"] {
        height       : 50px;
        border       : 1px solid @bordergrey;
        border-radius: 4px;
        background   : #fff;
        width        : 100%;
        padding      : 5px 10px;
        font-size    : 16px;
    }
}

.FormChoice fieldset {
    font-size: 14px;
    border   : none;
    padding  : 0 0 20px 0;
    display: flex;
    flex-wrap: wrap;
    width: 100%;

    label {
        @media(min-width: @screen-width-tablet){
            width: 50%;
        }
    }
}

.FormSubmitButton {
    padding       : 16px 50px;
    background    : @accent-second;

    &:hover,
    &:focus,
    &:active {
        outline   : none;
        background: #656F71;
    }
}

input.invalid,
input.input-validation-error {
    border: 1px solid @fail;
}

.form__label__description {
    font-size     : 13px;
    padding-bottom: 5px;
}

