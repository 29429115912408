.stayconnectedblock {

    &-heading, h2, h3, h4 {
        text-align: center;
        margin: 0 0 10px 0;
        font-family: @font-family-sans-serif;

        @media (min-width: @screen-width-tablet ) {
            text-align: left;
        }
    }

    .social-media-link-collection {
        display: flex;
        justify-content: center;

        @media (min-width: @screen-width-tablet ) {
            justify-content: flex-start;
            margin-left: -5px;
            flex-direction: column;
        }

        &__item {
            display: flex;
            align-items: center;
        }

        a {
            &.social-media-link {
                margin: 5px;
                width: 30px;
                height: 30px;
                display: flex;
                border-radius: 17px;
                background-color: white;
                justify-content: center;
                align-items: center;
                flex-grow: 0;
                flex-shrink: 0;

                &__desktop {
                    display: none;

                    @media (min-width: @screen-width-tablet ) {
                        display: block;
                    }
                }
            }
        }
    }
}
