.hero {
    position : relative;
    display  : flex;
    flex-wrap: wrap;

    .container {
        width: 100%;
        order: 2;

        @media (min-width: @screen-width-xl-desktop) {
            position: relative;
            height  : 500px;
        }

        @media (min-width: @screen-width-xxxl-desktop) {
            height: 600px;
        }
    }

    &__image {
        order          : 1;
        position       : relative;
        height         : 200px;
        overflow       : hidden;
        display        : flex;
        justify-content: center;
        align-items    : center;
        width          : 100%;

        @media (min-width: @screen-width-desktop) {
            height: 400px;
        }

        @media (min-width: @screen-width-xl-desktop) {
            height  : 500px;
            position: absolute;
            top     : 0;
            left    : 0;
        }

        @media (min-width: @screen-width-xxxl-desktop) {
            height: 600px;
        }

        picture img {
            width: 100%;

            @media (min-width: @screen-width-lg-desktop) {
                position : absolute;
                top      : 50%;
                left     : 50%;
                transform: translate(-50%, -50%);
            }
        }
    }

    &__content {
        background: #fff;
        padding   : 35px 35px 40px 35px;
        margin    : 0 -15px;
        text-align: center;
        z-index   : 5;

        @media (min-width: @screen-width-desktop) {
            margin : 0 -40px;
            padding: 55px 70px 50px 70px;
        }

        @media (min-width: @screen-width-lg-desktop) {
            background: rgb(255, 255, 255);
            background: -moz-linear-gradient(180deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0.8) 100%);
            background: -webkit-linear-gradient(180deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0.8) 100%);
            background: linear-gradient(180deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0.8) 100%);
            filter    : progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff", endColorstr="#ffffff", GradientType=1);
            width     : 420px;
            padding   : 40px;
            position  : absolute;
            top       : 50%;
            transform : translateY(-50%);
            margin    : 0;

            &--left {
                left: 55px;

                h1{
                    color:@accent-second;
                }
            }

            &--right {
                right: 55px;
            }

            &--centered {
                left     : 50%;
                transform: translateY(-50%) translateX(-50%);
            }
        }

        &--no-text {
            background-color: rgba(0, 0, 0, 0);
        }

        p {
            color: @accent-second;
        }

        button {
            margin-top: 10px;
            width     : 100%;

            @media (min-width:@screen-width-tablet) and (max-width:@screen-width-desktop) {
                width: inherit;
            }
        }
    }
}