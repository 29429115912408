.cookie-consent-bar {
    display: none;
    background: @highlight;
    text-align: center;
    flex-wrap: wrap;
    padding: 15px 23px;
    color: black;
    position: fixed;
    bottom: 0;
    right: 0;
    z-index: 40;
    width: 100%;

    @media (min-width: @screen-width-desktop) {
        width: 326px;
        height: auto;
        right: 20px;
        bottom: 20px;
        border-radius: 4px;
    }

    &__message {
        width: 100%;

        p {
            margin: 0 0 5px 0;
        }
    }

    &__acceptButton {
        margin: 10px auto 0 auto;
        width: 100%;

        @media (min-width: @screen-width-desktop) {
            width: unset;
        }
    }

    a {
        text-decoration: underline;
    }
}
