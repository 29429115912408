.js-toggle-customcolorflyin {
    background     : none;
    padding        : 0;
    display        : flex;
    flex-direction : column;
    justify-content: center;
    align-items    : center;

    svg {
        height    : 50px;
    }

    span {
        font-size  : 14px;
        font-style : italic;
        color      : @grey;
        font-weight: normal;
        padding-top: 5px;
    }
}

.customcolor-flyin {
    background        : #fff;
    z-index           : 100;
    position          : fixed;
    top               : 0;
    bottom            : 0px;
    right             : -100%;
    width             : 90%;
    height            : 100%;
    overflow          : hidden;
    -webkit-transition: right 0.5s ease;
    -moz-transition   : right 0.5s ease;
    -ms-transition    : right 0.5s ease;
    -o-transition     : right 0.5s ease;
    transition        : right 0.5s ease;
    padding           : 45px 20px;

    &.colorpicker-expanded {
        right: 0;
    }

    @media (min-width: 375px) {
        width: 325px;
    }

    @media (min-width: @screen-width-desktop) {
        width  : 525px;
        padding: 45px 30px;
    }

    @media (min-width: @screen-width-xl-desktop) {
        width: 605px;
    }

    &-closebtn {
        width        : 16px;
        height       : 16px;
        position     : absolute;
        top          : 15px;
        right        : 15px;
        border       : none;
        padding      : 0;
        cursor       : pointer;
        border-radius: 0;
        background   : none;
    }

    .colors {
        display        : flex;
        justify-content: flex-start;
        align-items    : baseline;
        flex-wrap      : wrap;

        .color {
            text-align    : center;
            width         : calc(33.3% - 10px);
            display       : flex;
            flex-direction: column;
            align-items   : center;
            margin        : 15px 5px;
            font-size     : 12px;
            font-style    : italic;
            color         : @grey;
            cursor        : pointer;

            @media (min-width: @screen-width-desktop) {
                width    : calc(20% - 10px);
                font-size: 14px;
            }

            span,
            svg {
                width        : 50px;
                height       : 50px;
                border-radius: 50%;
                margin-bottom: 5px;
                transition   : all .2s ease;

                &:hover,
                &.active {
                    box-shadow: 0 0 0 1px #000;
                    border    : 4px solid #fff;
                }
            }
        }
    }

    .color-type-choice {
        padding: 10px 0 20px 0;
    }

    .color-choice {
        padding: 20px 0 10px 0;
    }

    .ncs {
        display    : flex;
        align-items: center;
        flex-wrap  : wrap;
        padding    : 10px 0;

        label {
            font-size  : 13px;
            font-weight: 600;
            display    : block;
            width      : 100%;
        }

        input {
            width        : 200px;
            height       : 50px;
            border-radius: 4px;
            border       : solid 1px @bordergrey;
            padding      : 10px;
            background   : @backgroundgrey;

            @media (min-width: @screen-width-desktop) {
                width: 259px;
            }
        }

        svg {
            height     : 50px;
            margin-left: 10px;
        }
    }

    &-top {
        overflow-y: auto;
        height    : 85%;

        @media (min-width: @screen-width-desktop) {
            height: 90%;
        }

        @media (min-width: @screen-width-lg-desktop) {
            height: 85%;
        }
    }

    &-bottom {
        position  : absolute;
        bottom    : 0;
        left      : 0;
        padding   : 20px 30px;
        background: @backgroundgrey;
        width     : 100%;

        .btn-green-arrow {
            width     : 100%;
            margin-top: 10px;
        }
    }
}