.notfoundpage {
    h1{
        font-size: 48px;
    }
    &__text {
        max-width: 820px;
        font-size: 22px;
        line-height: 1.73;
        letter-spacing: -0.63px;
        text-align: center;
        margin: auto;

        p {
            font-size: 22px;
            line-height: 1.73;
            letter-spacing: -0.63px;
            text-align: center;
        }
    }

    .textpage__separator {
        padding: 0px 0 60px 0;
    }

    &__back {
        text-align: center;
        padding-bottom: 80px;
    }

    .parentlink{
        text-align: center;
        padding-bottom: 45px;
    }
}
