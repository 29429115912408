.textpage {
    padding: 20px 0;

    @media (min-width: @screen-width-lg-desktop ) {
        padding: 20px 0;
    }

    .hero {
        margin-bottom: 25px;
    }

    h1 {
        text-align: center;
        padding-bottom: 10px;

        + h2 {
            text-align: center;
        }
    }

    &__heading {
        width: 100%;
        margin: 0 auto;

        @media (min-width: @screen-width-lg-desktop ) {
            max-width: 823px;
        }
    }

    .preamble {
        width: 100%;
        margin: 0 auto;

        @media (min-width: @screen-width-lg-desktop ) {
            max-width: 823px;
        }
    }

    p {
        margin: 0 0 30px 0;
    }

    &__separator {
        padding: 40px 0 50px 0;

        @media (min-width: @screen-width-lg-desktop ) {
            padding: 60px 0;
        }

        span {
            width: 75px;
            height: 2px;
            background: @lightgrey;
            margin: 0 auto;
            display: block;
        }
    }

    &__text {
        width: 100%;
        margin: 0 auto;

        @media (min-width: @screen-width-desktop ) {
            width: 550px;
        }

        @media (min-width: @screen-width-lg-desktop ) {
            width: 605px;
        }

        ul,
        ol {
            padding: 0 0 0 15px;

            li {
                padding-bottom: 10px;
                line-height: 1.75;
                letter-spacing: -0.46px;
            }
        }

        p a {
            text-decoration: underline;
        }
    }
}
